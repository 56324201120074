import { Button, Typography, TypographySizeEnums } from 'components'
import {
  ContentWrapper,
  StyledMotionDiv,
  TypographyContainer,
  VariantsWrapper,
  tabVariants,
} from './styled-components'
import { TypographyVariantEnums, ButtonVariantEnums } from 'components'
import {
  GeneratorObjectiveEnums,
  GeneratorStepEnums,
  GeneratorVariantEnums,
  IdeationProps,
} from 'pages/generator/generator.interface'
import { useState } from 'react'

const Ideation = ({ choices, setChoices, setGeneratorStep }: IdeationProps) => {
  const [step, setStep] = useState<number>(0)

  const handleVariantClick = (variant: GeneratorVariantEnums) => {
    setChoices({ ...choices, variant })
    setStep(2)
  }

  const handleObjectiveClick = (objective: GeneratorObjectiveEnums) => {
    setChoices({ ...choices, objective })
    setGeneratorStep(GeneratorStepEnums.ARGENERATION)
  }

  return (
    <ContentWrapper>
      <StyledMotionDiv
        initial="open"
        animate={step === 0 ? 'open' : step > 0 ? 'closed' : 'notOpened'}
        variants={tabVariants}
      >
        <TypographyContainer>
          <Typography variant={TypographyVariantEnums.TITLE}>
          Launch AR Contest Campaigns that Go Viral and Convert
          </Typography>
          <Typography
            variant={TypographyVariantEnums.SUBTITLE}
            size={TypographySizeEnums.LARGE}
          >
            AI-powered AR marketing tool to reach your goals
          </Typography>
        </TypographyContainer>
        <VariantsWrapper>
          <div></div>
          <Button
            variant={ButtonVariantEnums.OUTLINE}
            onClick={() => setStep(1)}
          >
            How it works
          </Button>
        </VariantsWrapper>
      </StyledMotionDiv>
      <StyledMotionDiv
        initial="closed"
        animate={step === 1 ? 'open' : step > 1 ? 'closed' : 'notOpened'}
        variants={tabVariants}
      >
        <TypographyContainer>
          <Typography variant={TypographyVariantEnums.TITLE}>
            Hi, I<span>'</span>m Artttify I can help you to ideate, generate and
            promote AR Marketing campaign
          </Typography>
          <Typography
            variant={TypographyVariantEnums.SUBTITLE}
            size={TypographySizeEnums.LARGE}
          >
            Please choose campaign type to proceed with
          </Typography>
        </TypographyContainer>
        <VariantsWrapper>
          <Button
            variant={ButtonVariantEnums.OUTLINE}
            onClick={() => handleVariantClick(GeneratorVariantEnums.CLASSIC)}
          >
            {GeneratorVariantEnums.CLASSIC}
          </Button>
          <Button
            variant={ButtonVariantEnums.OUTLINE}
            onClick={() => handleVariantClick(GeneratorVariantEnums.MODERN)}
          >
            {GeneratorVariantEnums.MODERN}
          </Button>
          <Button
            variant={ButtonVariantEnums.OUTLINE}
            onClick={() => handleVariantClick(GeneratorVariantEnums.WILD)}
          >
            {GeneratorVariantEnums.WILD}
          </Button>
        </VariantsWrapper>
      </StyledMotionDiv>
      <StyledMotionDiv
        initial="closed"
        animate={step === 2 ? 'open' : step > 2 ? 'closed' : 'notOpened'}
        variants={tabVariants}
      >
        <TypographyContainer>
          <Typography variant={TypographyVariantEnums.TITLE}>
            Share the campaign objective
          </Typography>
          <Typography
            variant={TypographyVariantEnums.SUBTITLE}
            size={TypographySizeEnums.LARGE}
          >
            Please choose campaign type to proceed with
          </Typography>
        </TypographyContainer>
        <VariantsWrapper>
          <Button
            variant={ButtonVariantEnums.OUTLINE}
            onClick={() =>
              handleObjectiveClick(GeneratorObjectiveEnums.LIPSTICK)
            }
          >
            {GeneratorObjectiveEnums.LIPSTICK}
          </Button>
          <Button
            variant={ButtonVariantEnums.OUTLINE}
            onClick={() => handleObjectiveClick(GeneratorObjectiveEnums.SCROLL)}
          >
            {GeneratorObjectiveEnums.SCROLL}
          </Button>
          <Button
            variant={ButtonVariantEnums.OUTLINE}
            onClick={() =>
              handleObjectiveClick(GeneratorObjectiveEnums.SUNGLASSES)
            }
          >
            {GeneratorObjectiveEnums.SUNGLASSES}
          </Button>
        </VariantsWrapper>
      </StyledMotionDiv>
    </ContentWrapper>
  )
}

export default Ideation
