export interface ButtonProps {
  children: React.ReactNode
  onClick?: () => void
  variant: ButtonVariantEnums
  to?: string | null
  disabled?: boolean
  active?: boolean
  gridOrder?: number
  width?: string
}

export enum ButtonVariantEnums {
  FILL = 'fill',
  ICON = 'icon',
  OUTLINE = 'outline',
  TEXT = 'text',
  RADIO = 'radio',
}
