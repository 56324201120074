import { motion } from 'framer-motion'
import styled, { keyframes } from 'styled-components'

export const StyledLoaderWrapper = styled(motion.div)`
  border-radius: 50%;
  padding: 1em;
`

const ringRadius = '7rem'
const ringSectors = 30
const animDuration = '8s'

const tiltSpin = keyframes`
  from {
    transform: rotateY(0) rotateX(30deg);
  }
  to {
    transform: rotateY(1turn) rotateX(30deg);
  }
`

const spin = keyframes`
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(1turn);
  }
`

const reverseSpin = keyframes`
  from {
    transform: rotateY(2turn);
  } to {
    transform: rotateY(0);
  }
`

export const Ring = styled.div<{ index: number }>`
  font-size: 2em;
  position: relative;
  height: 3rem;
  width: 1.5rem;
  transform-style: preserve-3d;
  animation-name: ${({ index }) => (index % 2 === 0 ? reverseSpin : spin)};
  animation-duration: ${animDuration};
  animation-iteration-count: infinite;
  animation-timing-function: linear;
`

export const Sector = styled.div<{
  index: number
}>`
  font-weight: 600;
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  text-transform: uppercase;

  :empty:before {
    display: inline-block;
    width: 24px;
    height: 2px;
    background: #3df1f1;
    content: '';
  }

  ${({ index }) => {
    const angle = (360 / ringSectors) * (index - 1)
    return `transform: rotateY(${angle}deg) translateZ(${ringRadius}) !important;`
  }}
`

export const PreloaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  color: #3df1f1;
  font-family: 'Nekst R';
  width: 17em;
  height: 17em;
  animation: ${tiltSpin} ${animDuration} linear infinite;
  transform-style: preserve-3d;
`
