import styled from 'styled-components'
import { CampaignStatusEnums } from './Campaign.interface'

export const Wrapper = styled.article`
  display: flex;
  padding: 20px;
  border-radius: 24px;
  font-family: 'Helvetica';
  max-height: 300px;
  color: #fff;
  gap: 10px;
  background: linear-gradient(
      154.04deg,
      rgba(255, 255, 255, 0) -0.71%,
      rgba(19, 26, 27, 0.192) 95.15%
    ),
    linear-gradient(
      211.05deg,
      rgba(255, 255, 255, 0.2) 0.51%,
      rgba(255, 255, 255, 0.05) 97.01%
    );
  border: 1px solid;
  border-image-source: linear-gradient(
    211.05deg,
    rgba(255, 255, 255, 0.2) 0.51%,
    rgba(255, 255, 255, 0.05) 97.01%
  );

  @media (max-width: 768px) {
    padding: 8px;
    border-radius: 16px;
  }
`

export const CampaignImage = styled.img`
  height: 100%;
  border-radius: 12px;

  @media (max-width: 768px) {
    width: 40%;
    object-fit: cover;
  }
`

export const CampaignContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  width: 80%;
  gap: 10px;
`

export const CampaignContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  align-items: center;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`

export const CampaignHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
`

export const CampaignLogoWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    background: #fff;
    border-radius: 50%;
    padding: 3px;
    width: 18px;
    height: 18px;
  }
`

export const CampaignArticle = styled.div<{ width?: number }>`
  display: flex;
  ${({ width }) => width && `width: ${width}%; font-size: 24px;`}
  gap: 10px;
  margin-right: 54px;
  flex-direction: column;

  @media (max-width: 768px) {
    margin-right: 0;
  }
`

export const CampaignArticleLabel = styled.p`
  font-family: 'Helvetica';
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
`

export const CampaignStatus = styled.p<{ status: CampaignStatusEnums }>`
  position: relative;
  ::before {
    content: '';
    width: 10px;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    left: -15px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ status }) =>
      status === CampaignStatusEnums.ONGOING
        ? 'rgba(65, 235, 204, 1)'
        : 'rgba(106, 74, 160, 1)'};
    margin-right: 5px;
  }
`

export const CampaignArticleValue = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    width: 20px;
    aspect-ratio: 1;
  }

  p {
    font-family: 'Helvetica';
  }

  @media (max-width: 768px) {
    gap: 5px;
    img {
      width: 16px;
    }
  }
`
