import Slider from 'react-slick'
import styled from 'styled-components'

export const StyledSlider = styled(Slider)`
  width: 100%;
  margin-bottom: 100px;

  @media (min-width: 1440px) {
    display: none;
  }
  }
`

export const CarouselItem = styled.div`
  display: flex !important;
  justify-content: center;
  align-items: center;

  :active,
  :focus {
    outline: none;
  }

  img {
    width: 90%;
  }
`
