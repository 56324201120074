import { CampaignStatusEnums } from 'components/Campaign/Campaign.interface'

const CampaignsData = [
  {
    task: 'The most liked video',
    reward: 'Free 1000 Mins',
    link: 'Campaigns/scroll',
    location: 'Georgia',
    status: CampaignStatusEnums.ONGOING,
    campaignLogoTitle: 'Scroll',
    campaignImage: require('assets/images/campaigns/scroll/scroll.jpg'),
    campaignLogo: require('assets/images/campaigns/scroll/logo.png'),
    disabled: false,
  },
  {
    task: '100+ likes',
    reward: 'Free 3 Month Pass',
    link: 'Campaigns/yoga-rooms',
    location: 'Georgia',
    status: CampaignStatusEnums.ONGOING,
    campaignLogoTitle: 'Yoga Rooms',
    campaignImage: require('assets/images/campaigns/yoga/thumbnail.jpg'),
    campaignLogo: require('assets/images/campaigns/yoga/logo.png'),
    disabled: false,
  },
  {
    task: '1 000+ Views',
    reward: 'Sustainable Beauty Box',
    link: 'Campaigns/2',
    location: 'UK',
    status: CampaignStatusEnums.FINISHED,
    campaignLogoTitle: 'Disraptor London',
    disabled: true,
    campaignImage: require('assets/images/campaigns/disraptor/thumbnail.jpg'),
    campaignLogo: require('assets/images/campaigns/disraptor/logo.png'),
  },
  {
    task: '300+ Likes',
    reward: 'Free 7-day vacation',
    link: 'Campaigns/3',
    location: 'Georgia',
    status: CampaignStatusEnums.FINISHED,
    campaignLogoTitle: 'Kokhta Mitarbi Resort',
    campaignImage: require('assets/images/campaigns/kokhta/thumbnail.jpg'),
    campaignLogo: require('assets/images/campaigns/kokhta/logo.png'),
    disabled: true,
  },
  {
    task: '2 000+ views',
    reward: 'Free Tickets',
    link: 'Campaigns/4',
    location: 'Poland',
    status: CampaignStatusEnums.FINISHED,
    campaignLogoTitle: 'On Air Festival',
    campaignImage: require('assets/images/campaigns/festival/thumbnail.jpg'),
    campaignLogo: require('assets/images/campaigns/festival/logo.png'),
    disabled: true,
  },
]

export default CampaignsData
