// interfaces
import { HeaderProps } from './header.interface'

// components
import Artiffy from 'components/Logo/Artiffy'
import {
  CalendlyButtonStyles,
  HeaderContainer,
  Introduction,
  MoreDetails,
  UserType,
  UserTypes,
  UserTypesMobile,
} from './styled-components'
import { UserTypeEnums } from 'globalInterfaces'
import { Button, WaitlistModal } from 'components'
import { ButtonVariantEnums } from 'components/Button/button.interface'
import { PopupButton } from 'react-calendly'
import { useEffect, useState } from 'react'
import { useWaitlistModalContext } from 'context/WaitlistModalContext'

const Header = ({ userType, setUserType }: HeaderProps) => {
  const [scrolled, setScrolled] = useState(false)
  const { waitlistModalOpen, setWaitlistModalOpen } = useWaitlistModalContext()

  const handleScroll = () => {
    const scrollThreshold = window.innerHeight - 100
    const totalScrollHeight = document.documentElement.scrollHeight - 200

    if (
      window.scrollY >= scrollThreshold &&
      totalScrollHeight >= window.scrollY + scrollThreshold
    ) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <>
      <HeaderContainer scrolled={scrolled}>
        <Introduction>
          <Artiffy />
          <UserTypes>
            <UserType
              active={userType === UserTypeEnums.BRAND}
              onClick={() => setUserType(UserTypeEnums.BRAND)}
            >
              {UserTypeEnums.BRAND}
            </UserType>
            <UserType
              active={userType === UserTypeEnums.AMBASSADOR}
              onClick={() => setUserType(UserTypeEnums.AMBASSADOR)}
            >
              {UserTypeEnums.AMBASSADOR}
            </UserType>
          </UserTypes>
        </Introduction>
        <MoreDetails>
          {userType === UserTypeEnums.BRAND && (
            <>
              <PopupButton
                url="https://calendly.com/mariam-artttify"
                rootElement={document.getElementById('root')!}
                text="Talk With Us"
                styles={CalendlyButtonStyles}
              />
              <Button
                variant={ButtonVariantEnums.FILL}
                onClick={() => setWaitlistModalOpen(true)}
              >
                Join Us
              </Button>
            </>
          )}
        </MoreDetails>
      </HeaderContainer>
      {waitlistModalOpen && (
        <WaitlistModal
          isOpen={waitlistModalOpen}
          setIsOpen={setWaitlistModalOpen}
        />
      )}
      <UserTypesMobile>
        <UserType
          active={userType === UserTypeEnums.BRAND}
          onClick={() => setUserType(UserTypeEnums.BRAND)}
        >
          {UserTypeEnums.BRAND}
        </UserType>
        <UserType
          active={userType === UserTypeEnums.AMBASSADOR}
          onClick={() => setUserType(UserTypeEnums.AMBASSADOR)}
        >
          {UserTypeEnums.AMBASSADOR}
        </UserType>
      </UserTypesMobile>
    </>
  )
}

export default Header
