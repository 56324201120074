import Slider from 'react-slick'
import styled from 'styled-components'

export const StyledSlider = styled(Slider)`
  width: 100%;
  margin-bottom: 100px;

  .slick-prev:before,
  .slick-next:before {
    display: none;
  }

  .carousel-item-what-people-thinks {
    text-align: center;
    transition: 1s;
    width: 100%;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    margin-block: 55px;

    .container {
      display: flex !important;
      gap: 20px;
      justify-content: space-between;
      border: 1px solid #ffffff60;
      min-height: 220px;
      padding: 30px;
      width: 90%;
      flex-direction: column;
      gap: 10px;
      border-radius: 24px;
      color: #fff;
      background: linear-gradient(
        141deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(19, 26, 27, 0.14) 100%
      );
      box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.5);
    }

    :active,
    :focus {
      outline: none;
    }
  }

  .carousel-item-what-people-thinks:not(
      .slick-center .carousel-item-what-people-thinks
    ) {
    opacity: 0.5;
    scale: 0.9;
  }

  img {
    border-radius: 10px;
  }
`

export const SlideFooter = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  img {
    border-radius: 50%;
    width: 40px;
    aspect-ratio: 1;
  }
`

export const SlideFooterTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  align-items: flex-start;

  h6 {
    font-size: 1.2rem;
  }

  p {
    font-size: 1rem;
  }
`

export const PrevArrow = styled.div`
  position: absolute;
  left: 46%;
  @media (max-width: 1768px) {
    left: 45%;
  }
  @media (max-width: 1368px) {
    display: none !important;
  }
  width: 60px;
  height: 60px;
  transition: 0.3s;
  transform: translateY(140px);
  border-radius: 30px;
  border: 1px solid #ffffff60;
  background: rgba(88, 68, 68, 0.1);
  display: flex !important;
  justify-content: center;
  align-items: center;
`
export const NextArrow = styled.div`
  position: absolute;
  right: 46%;
  @media (max-width: 1768px) {
    right: 45%;
  }
  @media (max-width: 1368px) {
    display: none !important;
  }
  transform: translateY(140px);
  transition: 0.3s;
  border: 1px solid #ffffff50;
  width: 60px;
  border-radius: 30px;
  background: rgba(88, 68, 68, 0.2);
  height: 60px;
  display: flex !important;
  justify-content: center;
  align-items: center;
`
