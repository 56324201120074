import {
  CustomVideoPlayer,
  ImageColumn,
  ImagesContainer,
  Typography,
  TypographyAlignEnums,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'
import { ArticleContent, Wrapper } from './stylde-components'

const InvolveMillions = () => {
  return (
    <Wrapper>
      <ArticleContent>
        <Typography
          variant={TypographyVariantEnums.TITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.LEFT}
        >
          Unlock Rewards <br />
          with AR <span>!</span>
        </Typography>
        <Typography
          variant={TypographyVariantEnums.SUBTITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.LEFT}
        >
          Participate in Brands AR Challenge Campaigns, <br/> have fun, and win prizes.


        </Typography>
      </ArticleContent>
      <ImagesContainer>
        <ImageColumn>
          <CustomVideoPlayer
            videoSrc={require('assets/videos/ambassadros_page/1.mov')}
            play
          />
          <CustomVideoPlayer
            videoSrc={require('assets/videos/ambassadros_page/5.mov')}
            play
          />
        </ImageColumn>
        <ImageColumn transform={30}>
          <CustomVideoPlayer
            videoSrc={require('assets/videos/ambassadros_page/4.mov')}
            play
          />
          <CustomVideoPlayer
            videoSrc={require('assets/videos/ambassadros_page/3.mov')}
            play
          />
        </ImageColumn>
      </ImagesContainer>
    </Wrapper>
  )
}

export default InvolveMillions
