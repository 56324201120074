import styled from 'styled-components'
import { ButtonVariantEnums } from './button.interface'

export const ButtonWrapper = styled.button<{
  variant: ButtonVariantEnums
  disabled?: boolean
  gridOrder?: number
  active?: boolean
  width?: string
}>`
  border-radius: 30px;
  font-family: 'Nekst R';
  padding: 15px 40px;
  font-size: 16px;
  border-radius: 30px;
  color: white;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: center;
  position: relative;

  ${({ gridOrder }) => gridOrder && `order: ${gridOrder};`}
  ${({ width }) => width && `width: ${width};`}
  ${({ variant, active }) => {
    switch (variant) {
      case ButtonVariantEnums.FILL:
        return `
        background: rgba(41, 38, 43);
        transition: 0.5s;
        border: 1px solid transparent;

        ::after {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: calc(100% + 4px);
          height: calc(100% + 4px);
          transform: translate(-2px, -2px);
          background: linear-gradient(180deg, #3EFFD8 -35%, #FFFFFF -34.99%, rgba(255, 255, 255, 0) 100%),
          linear-gradient(0deg, rgba(88, 68, 68, 0.3), rgba(88, 68, 68, 0.3));
          border-radius: 30px;
          z-index: -1;
          transition: 0.5s;
        }

        :hover {
          border-color: #ffffff90;
        }
        `
      case ButtonVariantEnums.ICON:
        return `
          background-color: transparent;
          border: 1px solid #ffffff40;
          transition: background-color 0.2s ease-in-out;
          width: 35px;
          height: 35px;
          padding: 0;

          :hover {
            background: rgba(41, 38, 43);
          }
          `
      case ButtonVariantEnums.OUTLINE:
        return `
          background-color: transparent;
          border: 1px solid transparent;
          transition: 0.5s;

          display: flow-root;
          position: relative;
          padding: 1.3rem;
      
          
          ::before {
            content: "";
            position: absolute;
            top: -1px;
            left: -1px;
            right: -1px;
            bottom: -1px;
            border-radius: 5px;
            border: 1px solid transparent;
            border-radius: 30px;
            background: linear-gradient(180deg, #FFFFFF 0%, rgba(255, 255, 255, 0.5) 50%,rgba(0, 0, 0, 0) 100%) border-box;
            -webkit-mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;            
          }
          

          :hover {
            border-color: #ffffff70;
          }
        `
      case ButtonVariantEnums.RADIO:
        return `
        background: rgba(255,255,255, 0.1);
        transition: 0.5s;
        border: 1px solid ${active ? 'rgba(255, 255, 255, 1)' : 'transparent'};
        

        color: ${
          active ? 'rgba(255, 255, 255, 1)' : 'rgba(255, 255, 255, 0.2)'
        };
          
        `
      case ButtonVariantEnums.TEXT:
        return `
          background-color: transparent;
          color: #ffffff;
        `
    }
  }}

  ${({ disabled }) =>
    disabled &&
    `
      cursor: not-allowed;
      color:rgba(255, 255, 255, 0.3);
      border: 1px solid rgba(255, 255, 255, 0.18);

      :after {
        display: none;
      }

      :hover {
        border-color: rgba(255, 255, 255, 0.18);
      }
    `}

  @media (max-width: 1024px) {
    padding: 15px 20px;
    font-size: 14px;
  }
`
