export enum GeneratorStepEnums {
  IDEATION = 'Ideation',
  ARGENERATION = 'AR Generation',
  QUESTIFICATION = 'Questification',
  LAUNCH = 'Launch',
}

export enum GeneratorVariantEnums {
  CLASSIC = 'Classic',
  MODERN = 'Modern',
  WILD = 'Wild',
}

export enum GeneratorObjectiveEnums {
  LIPSTICK = '“Grow new lipstick’s consumer retention”',
  SCROLL = '“Build Community for Scroll”',
  SUNGLASSES = '“Increase sunglasses brand awareness”',
}

export interface GeneratorChoices {
  variant: GeneratorVariantEnums | null
  objective: GeneratorObjectiveEnums | null
}

export interface IdeationProps {
  choices: GeneratorChoices
  setChoices: React.Dispatch<React.SetStateAction<GeneratorChoices>>
  setGeneratorStep: React.Dispatch<React.SetStateAction<GeneratorStepEnums>>
}

export interface ARGenerationProps {
  generatorStep: GeneratorStepEnums
  setGeneratorStep: React.Dispatch<React.SetStateAction<GeneratorStepEnums>>
}

export interface QuestificationProps {
  choices: GeneratorChoices
  generatorStep: GeneratorStepEnums
  setGeneratorStep: React.Dispatch<React.SetStateAction<GeneratorStepEnums>>
}
export interface LaunchProps {
  handleLaunch: any
}
