import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
} from 'react'
import { GeneratorStepEnums } from 'pages/generator/generator.interface'

interface GeneratorContextProps {
  currentStep: GeneratorStepEnums
  updateCurrentStep: Dispatch<SetStateAction<GeneratorStepEnums>>
}

const GeneratorContext = createContext<GeneratorContextProps | null>(null)

export const useGeneratorContext = () => {
  const context = useContext(GeneratorContext)
  if (context === null) {
    throw new Error(
      'useGeneratorContext must be used within a GeneratorProvider',
    )
  }
  return context
}

interface GeneratorProviderProps {
  children: ReactNode
}

const GeneratorProvider: React.FC<GeneratorProviderProps> = ({ children }) => {
  const [currentStep, updateCurrentStep] = useState<GeneratorStepEnums>(
    GeneratorStepEnums.IDEATION,
  )

  return (
    <GeneratorContext.Provider value={{ currentStep, updateCurrentStep }}>
      {children}
    </GeneratorContext.Provider>
  )
}

export default GeneratorProvider
