import { useState } from 'react'
import { SelectProps } from './select.interface'
import { customStyles } from './styled-components'
import Select from 'react-select'

const CustomSelect = ({ options, placeholder }: SelectProps) => {
  const [selectedOption, setSelectedOption] = useState(options[0])

  const handleSelectChange = (selectedOption: any) => {
    setSelectedOption(selectedOption)
  }

  return (
    <Select
      options={options}
      value={selectedOption}
      onChange={handleSelectChange}
      styles={customStyles}
      placeholder={placeholder}
      isSearchable={false}
    />
  )
}

export default CustomSelect
