export enum TypographyVariantEnums {
  TITLE = 'title',
  SUBTITLE = 'subTitle',
  PARAGRAPH = 'paragraph',
}

export enum TypographySizeEnums {
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
}

export enum TypographyAlignEnums {
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
}

export interface TypographyProps {
  variant: TypographyVariantEnums
  children: React.ReactNode
  link?: string
  color?: string
  align?: TypographyAlignEnums
  size?: TypographySizeEnums
  changeColorOnResponsive?: boolean
}
