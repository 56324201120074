import styled from 'styled-components'
import { GeneratorStepEnums } from 'pages/generator/generator.interface'

export const ContentContainer = styled.div<{
  currentGeneratorStep: GeneratorStepEnums | null
}>`
  display: flex;
  gap: 40px;
  margin-block: 95vh;
  margin-inline: 14px;
  z-index: 999;
  position: relative;
  flex-direction: column;
  width: calc(100% - 28px);
  border-radius: 24px;
  background-color: rgba(3, 3, 3, 0.7);
  backdrop-filter: blur(15px);
  transition: all 0.3s ease-in-out;

  ${({ currentGeneratorStep }) =>
    currentGeneratorStep === GeneratorStepEnums.QUESTIFICATION &&
    `
  @media (max-width: 1024px) {
   margin-top: 165vh; 
   margin-bottom: 20vh;
  }
  `}

  @media (max-width: 768px) {
    margin-inline: 0;
    width: 100%;
  }
`
