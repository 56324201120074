import React, { createContext, useContext, useState, ReactNode } from 'react'

interface WaitlistModalContextProps {
  waitlistModalOpen: boolean
  setWaitlistModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const WaitlistModalContext = createContext<
  WaitlistModalContextProps | undefined
>(undefined)

export const useWaitlistModalContext = () => {
  const context = useContext(WaitlistModalContext)
  if (context === undefined) {
    throw new Error(
      'useWaitlistModalContext must be used within a WaitlistModalProvider',
    )
  }
  return context
}

interface WaitlistModalProviderProps {
  children: ReactNode
}

const WaitlistModalProvider: React.FC<WaitlistModalProviderProps> = ({
  children,
}) => {
  const [waitlistModalOpen, setWaitlistModalOpen] = useState(false)

  return (
    <WaitlistModalContext.Provider
      value={{ waitlistModalOpen, setWaitlistModalOpen }}
    >
      {children}
    </WaitlistModalContext.Provider>
  )
}

export default WaitlistModalProvider
