import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { StyledSlider } from './styled-components'
import { Settings } from 'react-slick'
import dummyData from './DummyData'
import { CustomVideoPlayer } from 'components'

const CarouselMobile = () => {
  const settings: Settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    centerPadding: '0px',
  }

  return (
    <StyledSlider {...settings}>
      {dummyData.map((item, index) => (
        <div className="carousel-item" key={index}>
          <CustomVideoPlayer videoSrc={item.img} play />
        </div>
      ))}
    </StyledSlider>
  )
}

export default CarouselMobile
