import { ComparisonRow } from './styled-components'

// icons
import ProgressIcon from 'assets/icons/progress.svg'
import RegressIcon from 'assets/icons/regress.svg'

const ComparisonItem = ({
  left,
  right,
  label,
}: {
  left: string
  right: string
  label: string
}) => {
  return (
    <ComparisonRow>
      <h4 className="progress">
        <span>{left}</span>
        <img src={ProgressIcon} alt="progress-icon" />
      </h4>
      <h4 className="label">{label}</h4>
      <h4 className="regress">
        <span>{right}</span>
        <img src={RegressIcon} alt="progress-icon" />
      </h4>
    </ComparisonRow>
  )
}

export default ComparisonItem
