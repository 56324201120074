import styled from 'styled-components'

export const VideoContainer = styled.div`
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
  width: 100%;
`

export const VideoPlayer = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`
