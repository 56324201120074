import { Wrapper } from './styled-components'
import CampaignsData from './dummyData'
import { Campaign } from 'components'

const Campaigns = () => {
  return (
    <Wrapper>
      {CampaignsData.map((campaign, index) => (
        <Campaign {...campaign} key={index} />
      ))}
    </Wrapper>
  )
}

export default Campaigns
