import { CampaignObject, RewardIcons, TaskIcons } from './campaigns.interface'

const dummyData: CampaignObject[] = [
  {
    name: 'Yoga Rooms',
    url: 'yoga-rooms',
    title: 'Yoga Rooms',
    logoPath: 'brands/yoga rooms.png',
    activeTill: 'Active Till: November 31',
    description: [
      '🧘 Join the Yoga Rooms AR Challenge! 🧘',
      `🤝 To win Reward share the Reels video with friends and family on different socials to get as many likes as possible. Let's build a vibrant community of mindful yogis!`,
    ],
    instructions: [
      '📸 Create: Shoot a Reel with the Yoga Rooms IG filter',
      '🐾 Step 1: Open the Instagram Filter page and Save the Filter',
      '🐾 Step 2: Switch to Reels and take the video',
      '🌿 Express: Add your favorite affirmation in the video itself or as a post description.',
      '📢 Share: Post your video on Reels (Instagram) with the hashtag',
      '#YogaRoomsChallenge and tag @yoga_rooms',
    ],
    task: {
      icon: TaskIcons.INSTAGRAM,
      text: 'Get as many likes as possible',
    },
    reward: {
      icon: RewardIcons.GIFT,
      text:
        '🏆 1st place - 3 Months pass\n' +
        '🏆 2nd place - 1 Month pass\n' +
        '🏆 3rd place - 1 Week pass\n',
    },
    QR: 'images/campaigns/yoga/qr.png',
    QRText: 'To start a challenge scan a QR code with your phone',
    QRLink: `https://8cba45d5.sibforms.com/serve/MUIFAOZcw3t_BObS0QRGgkdwpd4yPnsufN48Zq4Dq4sKRarco7hcJ-miym_0kC9T3HEJyHiFUPDiOMO7jxXq_HxkHThIsCYmuIylBChbh33Kraz9CSAJIZRI9oBL2HZSVk7f90NoI40q0P4hnnN9gYfIWhcL2APmKZMZv9lAno1IhFAdbuD-0CuwC6ojTyVKB3u8y2I-kM9Kgo4m`,
    PreviewVideo: 'videos/campaigns/yoga.mp4',
  },
  {
    name: 'Scroll',
    url: 'scroll',
    title: 'Scroll',
    logoPath: 'images/campaigns/scroll/logo.png',
    activeTill: '🌟 Challenge Duration: November 31',
    description: [
      '🛴 Join the Scroll AR Challenge! 🛴',
      `Get ready to take your SCROLL journey to the next level with our exciting challenge! We invite you to embrace Eco green mindfulness and the power of augmented reality in a fun and innovative way.`,
    ],
    instructions: [
      '📸 Create: Shoot a video with Scroll IG filter',
      '📢 Share: Post your creative video on Reels (Instagram) with the hashtag #scrollARChallenge and tag @Scroll.eco',
    ],
    task: {
      icon: TaskIcons.INSTAGRAM,
      text: `🏆 The most liked video 3 owners will win exclusive Free minutes TO SCROLL.`,
    },
    reward: {
      icon: RewardIcons.GIFT,
      text: `1st place - 1000 mins
      2nd place - 600 mins
      3rd place - 400 mins`,
    },
    QR: 'images/campaigns/scroll/qr.jpg',
    QRText: 'To start a challenge scan a QR code with your phone',
    QRLink: `https://8cba45d5.sibforms.com/serve/MUIFAE7YyYLOHC0CuRiQxWLs9rci5KTwAUglvZ9d5grE6NHxq_Nda7DlbptLitOo011RpBTVsnh4VtI16gtHmmnI0I9_m6Cqjf0QVcNhnYUWR5hV7YFPxMtPr-3lIIveulF2P8I7T6zz1oGZKhTC9Gp3sA4abmni_LmQiudXry9_5LGHRckxrFQsyPgVbpeHXQ4oPo7EuwW3CqVQ`,
    PreviewVideo: 'videos/brand_page/4.MOV',
  },
]

export default dummyData
