import {
  Typography,
  TypographyAlignEnums,
  TypographyVariantEnums,
} from 'components'
import { GiftDiv, TxtWrapper } from './styled-components'

type PropType = {
  title: string
  imgSrc: string
  altTxt: string
  rewardTxt: string
}

const Reward = (props: PropType) => {
  const { title, imgSrc, altTxt, rewardTxt } = props
  return (
    <>
      <TxtWrapper>
        <Typography
          variant={TypographyVariantEnums.PARAGRAPH}
          color="white"
          align={TypographyAlignEnums.LEFT}
        >
          {title}
        </Typography>
      </TxtWrapper>
      <GiftDiv>
        <img src={require(`assets/${imgSrc}`)} alt={altTxt} />
        <Typography
          variant={TypographyVariantEnums.PARAGRAPH}
          align={TypographyAlignEnums.LEFT}
          color="white"
        >
          {rewardTxt}
        </Typography>
      </GiftDiv>
    </>
  )
}

export default Reward
