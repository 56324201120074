import {
  ARGenerationProps,
  GeneratorStepEnums,
} from 'pages/generator/generator.interface'
import {
  PreloaderContainer,
  Ring,
  Sector,
  StyledLoaderWrapper,
} from './styled-components'
import { useEffect } from 'react'

const ARGeneration = ({
  generatorStep,
  setGeneratorStep,
}: ARGenerationProps) => {
  const rings = 2 // Replace with your desired value
  const ringSectors = 30 // Replace with your desired value
  const text = 'Generating...' // Replace with your desired text

  const renderRings = () => {
    const ringsArray = []
    for (let r = 0; r < rings; ++r) {
      ringsArray.push(
        <Ring index={r} key={r} className="preloader__ring">
          {renderSectors()}
        </Ring>,
      )
    }
    return ringsArray
  }
  const renderSectors = () => {
    const sectors = []
    for (let s = 0; s < ringSectors; ++s) {
      sectors.push(
        <Sector key={s} className="preloader__sector" index={s}>
          {text[s] || ''}
        </Sector>,
      )
    }
    return sectors
  }

  useEffect(() => {
    if (generatorStep === GeneratorStepEnums.ARGENERATION) {
      setTimeout(() => {
        setGeneratorStep(GeneratorStepEnums.QUESTIFICATION)
      }, 4000)
    }
  }, [generatorStep, setGeneratorStep])

  return (
    <StyledLoaderWrapper>
      <PreloaderContainer>{renderRings()}</PreloaderContainer>
    </StyledLoaderWrapper>
  )
}

export default ARGeneration
