import styled from 'styled-components'

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  height: 95vh;
  position: fixed;
  width: 100%;
  justify-content: center;

  @media (max-width: 768px) {
    margin-inline: 20px;
    width: calc(100% - 40px);
  }
`

export const GetPrizesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`

export const ScanQRContainer = styled.div`
  display: flex;
  gap: 20px;
  padding: 20px;
  border-radius: 24px;
  background: linear-gradient(
      154.04deg,
      rgba(151, 100, 225, 0) -0.71%,
      rgba(64, 48, 102, 0.192) 95.15%
    ),
    linear-gradient(
      211.05deg,
      rgba(255, 255, 255, 0.2) 0.51%,
      rgba(255, 255, 255, 0.05) 97.01%
    );
  border: 1px solid;
  border-image-source: linear-gradient(
    211.05deg,
    rgba(255, 255, 255, 0.2) 0.51%,
    rgba(255, 255, 255, 0.05) 97.01%
  );

  img {
    background: #fff;
    border-radius: 12px;
    padding: 10px;
  }
`

export const ScanQRContent = styled.div`
  font-family: 'Helvetica';
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
`
