import {
  GeneratorObjectiveEnums,
  GeneratorStepEnums,
  GeneratorVariantEnums,
  QuestificationProps,
} from 'pages/generator/generator.interface'
import {
  HalfDivider,
  PlatformsWrapper,
  QuestificationContent,
  VideoContainer,
  Wrapper,
} from './styled-components'
import {
  Button,
  ButtonVariantEnums,
  CustomVideoPlayer,
  Select,
  Typography,
  TypographyAlignEnums,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'
import { useEffect, useState } from 'react'
import InstagramLogo from 'assets/images/campaigns/instagram.svg'
import TikTokLogo from 'assets/icons/tiktok.svg'

const Questification = ({
  choices,
  generatorStep,
  setGeneratorStep,
}: QuestificationProps) => {
  const [isContentVisible, setIsContentVisible] = useState<boolean>(false)
  const [activePlatform, setActivePlatform] = useState<string>('Instagram')

  useEffect(() => {
    if (generatorStep === GeneratorStepEnums.QUESTIFICATION) {
      setTimeout(() => {
        setIsContentVisible(true)
      }, 3000)
    }
  }, [generatorStep])
  const getVideoSrc = () => {
    let videoSrc = ''
    switch (choices.objective) {
      case GeneratorObjectiveEnums.LIPSTICK:
        switch (choices.variant) {
          case GeneratorVariantEnums.CLASSIC:
            videoSrc = require(`assets/videos/lipstick/classic.mov`)
            break
          case GeneratorVariantEnums.MODERN:
            videoSrc = require(`assets/videos/lipstick/modern.mov`)
            break
          case GeneratorVariantEnums.WILD:
            videoSrc = require(`assets/videos/lipstick/wild.mp4`)
        }
        break
      case GeneratorObjectiveEnums.SCROLL:
        switch (choices.variant) {
          case GeneratorVariantEnums.CLASSIC:
            videoSrc = require(`assets/videos/brand_page/4.MOV`)
            break
          case GeneratorVariantEnums.MODERN:
            videoSrc = require(`assets/videos/brand_page/4.MOV`)
            break
          case GeneratorVariantEnums.WILD:
            videoSrc = require(`assets/videos/brand_page/4.MOV`)
        }
        break
      case GeneratorObjectiveEnums.SUNGLASSES:
        switch (choices.variant) {
          case GeneratorVariantEnums.CLASSIC:
            videoSrc = require(`assets/videos/sunglasses/classic.mov`)
            break
          case GeneratorVariantEnums.MODERN:
            videoSrc = require(`assets/videos/sunglasses/modern.mov`)
            break
          case GeneratorVariantEnums.WILD:
            videoSrc = require(`assets/videos/sunglasses/wild.mov`)
        }
        break
    }

    return videoSrc
  }

  return (
    <Wrapper>
      <QuestificationContent isVisible={isContentVisible}>
        <Typography
          variant={TypographyVariantEnums.TITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.LEFT}
        >
          Issue reward for your brand’s ambassadors
        </Typography>
        <HalfDivider>
          <Typography
            variant={TypographyVariantEnums.SUBTITLE}
            size={TypographySizeEnums.LARGE}
            align={TypographyAlignEnums.LEFT}
          >
            Please enter details
          </Typography>
          <Typography
            variant={TypographyVariantEnums.PARAGRAPH}
            size={TypographySizeEnums.SMALL}
            align={TypographyAlignEnums.LEFT}
          >
            Choose social platform
          </Typography>
          <PlatformsWrapper>
            <Button
              variant={ButtonVariantEnums.RADIO}
              onClick={() => setActivePlatform('Instagram')}
              active={activePlatform === 'Instagram'}
            >
              <img src={InstagramLogo} alt="Social Media Icon" />
              <p> Instagram</p>
            </Button>
            <Button
              variant={ButtonVariantEnums.RADIO}
              onClick={() => setActivePlatform('Tiktok')}
              active={activePlatform === 'Tiktok'}
            >
              <img src={TikTokLogo} alt="Social Media Icon" />
              Tiktok
            </Button>
          </PlatformsWrapper>
          <Select
            options={[
              {
                value: '1',
                label: 'Most Views',
              },
              {
                value: '2',
                label: 'Most Likes',
              },
            ]}
            placeholder="Select Objective"
          />
          <Select
            options={[
              {
                value: '1',
                label: 'Free Sample',
              },
              {
                value: '2',
                label: 'Free Minutes',
              },
              {
                value: '3',
                label: '80% Discount',
              },
              {
                value: '4',
                label: 'Free Pass',
              },
              {
                value: '5',
                label: 'Your Own',
              },
            ]}
            placeholder="Select Prizes"
          />
          <Button
            width="60%"
            variant={ButtonVariantEnums.FILL}
            onClick={() => setGeneratorStep(GeneratorStepEnums.LAUNCH)}
          >
            Next
          </Button>
        </HalfDivider>
      </QuestificationContent>
      <VideoContainer>
        <CustomVideoPlayer
          videoSrc={getVideoSrc()}
          play={generatorStep === GeneratorStepEnums.QUESTIFICATION}
          styles={{
            height: '55vh',
          }}
        />
        <Typography
          variant={TypographyVariantEnums.PARAGRAPH}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.LEFT}
        >
          Preview AR Experience
          <br /> generated for you
        </Typography>
      </VideoContainer>
    </Wrapper>
  )
}

export default Questification
