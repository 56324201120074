import {
  Typography,
  TypographyVariantEnums,
  TypographySizeEnums,
  CustomVideoPlayer,
} from 'components'
import {
  SideDiv,
  Content,
  Wrapper,
  ComparisonTable,
  MiddleDiv,
  TryYourselfConteiner,
} from './styled-components'
import ComparisonItem from './ComparisonItem'

const ArIsMoreEffective = () => {
  return (
    <Wrapper>
      <Typography
        variant={TypographyVariantEnums.TITLE}
        size={TypographySizeEnums.LARGE}
      >
        Why to choose AR over traditional methods?
      </Typography>
      <Content>
        <SideDiv>
          <Typography
            variant={TypographyVariantEnums.TITLE}
            size={TypographySizeEnums.SMALL}
          >
            AR
          </Typography>
          <CustomVideoPlayer
            videoSrc={require('assets/videos/why-to-choose.mov')}
            play
          />
          <TryYourselfConteiner>
            <img
              src={require('assets/images/why-to-choose.png')}
              alt="qr code"
              style={{ width: '100px' }}
            />
            <p>
              Try <br /> yourself
            </p>
          </TryYourselfConteiner>
        </SideDiv>
        <MiddleDiv>
          <Typography
            variant={TypographyVariantEnums.TITLE}
            size={TypographySizeEnums.SMALL}
            color={'rgba(255,255,255, 0.5)'}
            changeColorOnResponsive
          >
            VS
          </Typography>
          <ComparisonTable top>
            <ComparisonItem left="90%" right="Low" label="Conversion rates" />
            <ComparisonItem left="35%" right="Low" label="Boosting sales" />
            <ComparisonItem left="70%" right="Low" label="Memory response" />
            <ComparisonItem left="4x" right="Minimal" label="View time" />
          </ComparisonTable>
        </MiddleDiv>
        <SideDiv>
          <Typography
            variant={TypographyVariantEnums.TITLE}
            size={TypographySizeEnums.SMALL}
          >
            Traditional
          </Typography>
          <img
            src={require('assets/images/arIsBetter2.png')}
            alt="Ar Is Better"
          />
        </SideDiv>
      </Content>
      <ComparisonTable>
        <ComparisonItem left="90%" right="Low" label="Conversion rates" />
        <ComparisonItem left="35%" right="Low" label="Boosting sales" />
        <ComparisonItem left="70%" right="Low" label="Memory response" />
        <ComparisonItem left="4x" right="Minimal" label="View time" />
      </ComparisonTable>
    </Wrapper>
  )
}

export default ArIsMoreEffective
