import Button from 'components/Button/Button'
import { CampaignProps } from './Campaign.interface'
import {
  CampaignContent,
  CampaignStatus,
  CampaignHeader,
  CampaignLogoWrapper,
  Wrapper,
  CampaignArticleLabel,
  CampaignArticleValue,
  CampaignArticle,
  CampaignContainer,
  CampaignImage,
} from './styled-components'
import { ButtonVariantEnums } from 'components'
import InstagramLogo from 'assets/images/campaigns/instagram.svg'
import MapPin from 'assets/images/campaigns/map-pin.svg'
import GiftSvg from 'assets/images/campaigns/gift.svg'

const Campaign = ({
  task,
  reward,
  location,
  status,
  campaignLogoTitle,
  campaignImage,
  campaignLogo,
  disabled,
  link,
}: CampaignProps) => {
  return (
    <Wrapper>
      <CampaignImage src={campaignImage} alt="campaign" />
      <CampaignContainer>
        <CampaignHeader>
          <CampaignLogoWrapper>
            <img src={campaignLogo} alt="campaign logo" />
            <h6>{campaignLogoTitle}</h6>
          </CampaignLogoWrapper>
          <CampaignStatus status={status}>{status}</CampaignStatus>
        </CampaignHeader>
        <CampaignContent>
          <CampaignArticle>
            <CampaignArticleLabel>Task</CampaignArticleLabel>
            <CampaignArticleValue>
              <img src={InstagramLogo} alt="Social Media Icon" />
              <p>{task}</p>
            </CampaignArticleValue>
          </CampaignArticle>
          <CampaignArticle>
            <CampaignArticleLabel>Location</CampaignArticleLabel>
            <CampaignArticleValue>
              <img src={MapPin} alt="Map Pin Icon" />
              <p>{location}</p>
            </CampaignArticleValue>
          </CampaignArticle>
          <CampaignArticle width={100}>
            <CampaignArticleLabel>Reward</CampaignArticleLabel>
            <CampaignArticleValue>
              <img src={GiftSvg} alt="Gift Icon" />
              <p>{reward}</p>
            </CampaignArticleValue>
          </CampaignArticle>
        </CampaignContent>
        <Button
          width="100%"
          variant={ButtonVariantEnums.FILL}
          to={link}
          disabled={disabled}
        >
          Join Now and Win
        </Button>
      </CampaignContainer>
    </Wrapper>
  )
}

export default Campaign
