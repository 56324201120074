import styled from 'styled-components'

export const ContentContainer = styled.div`
  display: flex;
  gap: 40px;
  margin-block: 75vh;
  margin-inline: 14px;
  z-index: 999;
  position: relative;
  padding: 80px 10%;
  flex-direction: column;
  width: calc(100% - 28px);
  border-radius: 24px;
  background-color: rgba(3, 3, 3, 0.7);
  backdrop-filter: blur(15px);

  @media (max-width: 768px) {
    padding: 24px 12px;
    margin-inline: 0;
    width: 100%;
  }
`
