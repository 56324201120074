import { Link } from 'react-router-dom'
import { ButtonProps } from './button.interface'
import { ButtonWrapper } from './styled-components'

const Button = ({
  children,
  variant,
  onClick,
  to,
  disabled,
  width,
  active,
  gridOrder,
}: ButtonProps) => {
  if (to && !disabled) {
    return (
      <Link to={to}>
        <ButtonWrapper width={width} gridOrder={gridOrder} variant={variant}>
          {children}
        </ButtonWrapper>
      </Link>
    )
  }

  return (
    <ButtonWrapper
      active={active}
      gridOrder={gridOrder}
      disabled={disabled}
      width={width}
      onClick={() => {
        if (!disabled && onClick) onClick()
      }}
      variant={variant}
    >
      {children}
    </ButtonWrapper>
  )
}

export default Button
