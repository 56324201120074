import { motion } from 'framer-motion'
import styled from 'styled-components'

export const ContentWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 17px;
  position: relative;
  max-width: 1880px;
  height: 600px;
  width: 800px;
  @media (max-width: 768px) {
    width: 490px;
  }

  @media (max-width: 490px) {
    width: 320px;
  }
`

export const VariantsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 16px;
  width: 732px;
  margin-inline: auto;
  margin-top: 39px;

  @media (max-width: 768px) {
    width: 490px;
    grid-template-columns: 1fr;
  }

  @media (max-width: 490px) {
    width: 320px;
  }
`

export const TypographyContainer = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  @media (max-width: 1024px) {
    width: 490px;
  }

  @media (max-width: 768px) {
    width: 320px;
  }
`

export const StyledMotionDiv = styled(motion.div)`
  margin: 0 auto;
  width: 100%;
  top: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transform: translate(-50%, -50%);
  position: absolute;
`

export const tabVariants = {
  notOpened: {
    x: 940,
    opacity: 0,
    scale: 0.9,
    transition: {
      x: {
        stiffness: 4000,
        velocity: -100,
        ease: 'easeInOut',
        delay: 0.5,
        duration: 0.5,
      },
      opacity: { duration: 0.3, ease: 'easeInOut', delay: 0.3 },
      scale: { duration: 0.3, ease: 'easeInOut', delay: 0.5 },
    },
  },
  open: {
    x: 0,
    opacity: 1,
    scale: 1,
    transition: {
      x: {
        stiffness: 4000,
        velocity: -100,
        duration: 0.5,
        delay: 0.3,
        ease: 'easeInOut',
      },
      opacity: { duration: 0.5, ease: 'easeInOut', delay: 0.3 },
      scale: { duration: 0.5, ease: 'easeInOut', delay: 0.5 },
    },
  },
  closed: {
    x: -940,
    opacity: 0,
    scale: 0.9,
    transition: {
      x: { stiffness: 4000, velocity: -100, ease: 'easeInOut', delay: 0.3 },
      opacity: { duration: 0.3, ease: 'easeInOut', delay: 0.1 },
      scale: { duration: 0.3, ease: 'easeInOut' },
    },
  },
}
