import {
  Article,
  Typography,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'
import { ArticleWrapper, Wrapper } from './styled-components'

const WhyChooseArtttify = () => {
  return (
    <Wrapper>
      <Typography
        variant={TypographyVariantEnums.TITLE}
        size={TypographySizeEnums.LARGE}
      >
        What you can do with Artttify?
      </Typography>
      <ArticleWrapper>
        <Article
          title="Create contest campaigns under 30 min"
          subtitle="Campaign Ideation, Content Generation and Promotion with the help of AI"
          width={60}
          leftWidth={60}
          rightWidth={40}
          image={require('assets/icons/light.png')}
        />
        <Article
          title="Boost engagement and receive huge UGC"
          subtitle="Elevate your brand's interaction with customers through engaging AR experiences"
          width={40}
          leftWidth={100}
          rightWidth={100}
          image={require('assets/icons/chotki.png')}
          vertical
        />
        <Article
          title="Foster brand loyalty and boost sales"
          subtitle="Build lasting connections with your audience, nurturing brand loyalty that stands the test of time"
          width={35}
          leftWidth={100}
          rightWidth={100}
          image={require('assets/icons/pencil.png')}
          vertical
        />
        <Article
          title="Turn your customers into brand advocates "
          subtitle="ARtttify unites fragmented processes and creates one space for entire AR campaign pipeline"
          width={65}
          leftWidth={70}
          rightWidth={30}
          image={require('assets/icons/phone analytics.png')}
        />
      </ArticleWrapper>
    </Wrapper>
  )
}

export default WhyChooseArtttify
