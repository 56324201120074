import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import {
  NextArrow,
  PrevArrow,
  SlideFooter,
  SlideFooterTextContainer,
  StyledSlider,
} from './styled-components'
import { Settings } from 'react-slick'
import ArrowIconLeft from 'assets/icons/left.svg'
import ArrowIconRight from 'assets/icons/right.svg'
import {
  Typography,
  TypographyAlignEnums,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'

const Carousel = ({
  data,
}: {
  data: {
    description: string
    author: {
      name: string
      position: string
      image: string
    }
  }[]
}) => {
  const settings: Settings = {
    infinite: true,
    speed: 500,
    autoplay: false,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    arrows: true,
    prevArrow: (
      <PrevArrow>
        <img src={ArrowIconLeft} alt="prev-arrow" />
      </PrevArrow>
    ),
    nextArrow: (
      <NextArrow>
        <img src={ArrowIconRight} alt="-arrow" />
      </NextArrow>
    ),
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <StyledSlider {...settings}>
      {data.map((item, index) => (
        <div className="carousel-item-what-people-thinks" key={index}>
          <div className="container">
            <Typography
              variant={TypographyVariantEnums.PARAGRAPH}
              size={TypographySizeEnums.SMALL}
              align={TypographyAlignEnums.LEFT}
            >
              {item.description}
            </Typography>
            <SlideFooter>
              <img src={item.author.image} alt="slider-item" />
              <SlideFooterTextContainer>
                <Typography
                  variant={TypographyVariantEnums.TITLE}
                  size={TypographySizeEnums.SMALL}
                  align={TypographyAlignEnums.LEFT}
                >
                  {item.author.name}
                </Typography>
                <Typography
                  variant={TypographyVariantEnums.SUBTITLE}
                  size={TypographySizeEnums.SMALL}
                  align={TypographyAlignEnums.LEFT}
                >
                  {item.author.position}
                </Typography>
              </SlideFooterTextContainer>
            </SlideFooter>
          </div>
        </div>
      ))}
    </StyledSlider>
  )
}

export default Carousel
