import styled from 'styled-components'

export const TxtWrapper = styled.p`
  display: none;
  margin: 30px 0 15px 0;
  @media screen and (width > 1300px) {
    display: flex;
  }
`

export const GiftDiv = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  p {
    white-space: pre-line;
  }
`
