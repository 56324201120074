import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 60px 10%;
  gap: 100px;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  max-width: 1880px;
  align-items: center;
  margin: 0 auto;
`

export const ImageWrapper = styled.div<{ full?: boolean }>`
  width: 100%;
  img {
    width: 100%;
    border-radius: 10px;
  }
  ${({ full }) =>
    full
      ? `
    @media (max-width: 1024px) {
      display: none;
    }
  `
      : `
    @media (min-width: 1024px) {
      display: none;
    }
  `}
`
