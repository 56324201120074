export enum ProgressStatusEnums {
  PROGRESS = 'PROGRESS',
  REGRESS = 'REGRESS',
}

const dummyData = [
  {
    img: require('assets/videos/brand_page/4.MOV'),
    avatar: require('assets/images/carousel-item-logo.png'),
    alt: 'Campaign for Scroll',
    title: 'Scroll',
    description: 'Increased Brand awareness by',
    perncent: 43,
    status: ProgressStatusEnums.PROGRESS,
  },
  {
    img: require('assets/videos/ambassadros_page/1.mov'),
    avatar: require('assets/images/carousel-item-logo.png'),
    alt: 'Campaign for Scroll',
    title: 'Scroll',
    description: 'Increased Brand awareness by',
    perncent: 43,
    status: ProgressStatusEnums.PROGRESS,
  },
  {
    img: require('assets/videos/brand_page/7.MP4'),
    avatar: require('assets/images/carousel-item-logo.png'),
    alt: 'Campaign for Scroll',
    title: 'Scroll',
    description: 'Increased Brand awareness by',
    perncent: 43,
    status: ProgressStatusEnums.PROGRESS,
  },
  {
    img: require('assets/videos/brand_page/3.mov'),
    avatar: require('assets/images/carousel-item-logo.png'),
    alt: 'Campaign for Scroll',
    title: 'Scroll',
    description: 'Increased Brand awareness by',
    perncent: 43,
    status: ProgressStatusEnums.PROGRESS,
  },
  {
    img: require('assets/videos/brand_page/5.mov'),
    avatar: require('assets/images/carousel-item-logo.png'),
    alt: 'Campaign for Scroll',
    title: 'Scroll',
    description: 'Increased Brand awareness by',
    perncent: 43,
    status: ProgressStatusEnums.PROGRESS,
  },
  {
    img: require('assets/videos/brand_page/6.MOV'),
    avatar: require('assets/images/carousel-item-logo.png'),
    alt: 'Campaign for Scroll',
    title: 'Scroll',
    description: 'Increased Brand awareness by',
    perncent: 43,
    status: ProgressStatusEnums.PROGRESS,
  },
  {
    img: require('assets/videos/brand_page/7.MP4'),
    avatar: require('assets/images/carousel-item-logo.png'),
    alt: 'Campaign for Scroll',
    title: 'Scroll',
    description: 'Increased Brand awareness by',
    perncent: 43,
    status: ProgressStatusEnums.PROGRESS,
  },
  {
    img: require('assets/videos/ambassadros_page/5.mov'),
    avatar: require('assets/images/carousel-item-logo.png'),
    alt: 'Campaign for Scroll',
    title: 'Scroll',
    description: 'Increased Brand awareness by',
    perncent: 43,
    status: ProgressStatusEnums.PROGRESS,
  },
]

export default dummyData
