export enum TaskIcons {
  INSTAGRAM = 'icons/Instagram.svg',
}

export enum RewardIcons {
  GIFT = 'images/campaigns/gift.svg',
}

export interface CampaignObject {
  name: string
  url: string
  title: string
  description: string[]
  logoPath: string
  activeTill: string
  instructions: string[]
  task: {
    icon: TaskIcons
    text: string
  }
  reward: {
    icon: RewardIcons
    text: string
  }
  QR: string
  QRText: string
  QRLink: string
  PreviewVideo: string
}
