import {
  Button,
  ButtonVariantEnums,
  CustomVideoPlayer,
  Reward,
  Typography,
  TypographyAlignEnums,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'
import { CampaignObject } from '../campaigns.interface'
import {
  Container,
  DeskImgDiv,
  HDiv,
  LogoDiv,
  QrButtonContainer,
  QrDiv,
  QrLogo,
  RDiv,
  RewardDiv,
  SDiv,
  TxtDiv,
  TxtWrapper,
  Wrapper,
} from './styled-components'

const Content = ({ campaign }: { campaign: CampaignObject }) => {
  const {
    title,
    description,
    logoPath,
    activeTill,
    instructions,
    task,
    reward,
    PreviewVideo,
    QR,
    QRLink,
    QRText,
  } = campaign
  return (
    <Wrapper>
      <Container>
        <SDiv>
          <TxtDiv>
            <HDiv>
              <LogoDiv>
                <img
                  src={require(`assets/${logoPath}`)}
                  alt="CampaignsHeader Logo"
                />
              </LogoDiv>
              <Typography
                variant={TypographyVariantEnums.SUBTITLE}
                size={TypographySizeEnums.LARGE}
                color="white"
                align={TypographyAlignEnums.LEFT}
              >
                {title}
              </Typography>
            </HDiv>
            <TxtWrapper>
              <Typography
                variant={TypographyVariantEnums.PARAGRAPH}
                size={TypographySizeEnums.MEDIUM}
                align={TypographyAlignEnums.LEFT}
              >
                📅 {activeTill}
              </Typography>
              <br />
              <br />
              {description.map((item, index) => (
                <Typography
                  variant={TypographyVariantEnums.PARAGRAPH}
                  size={TypographySizeEnums.MEDIUM}
                  align={TypographyAlignEnums.LEFT}
                  key={index}
                >
                  {item}
                </Typography>
              ))}
            </TxtWrapper>
            <TxtWrapper>
              {instructions.map((item, index) => (
                <Typography
                  variant={TypographyVariantEnums.PARAGRAPH}
                  size={TypographySizeEnums.MEDIUM}
                  align={TypographyAlignEnums.LEFT}
                  color="white"
                  key={index}
                >
                  {item}
                </Typography>
              ))}
            </TxtWrapper>
            <RDiv>
              <RewardDiv>
                <Reward
                  title={'Reward'}
                  imgSrc={reward.icon}
                  altTxt={'Gift'}
                  rewardTxt={reward.text}
                />
              </RewardDiv>
              <div>
                <Reward
                  title={'Task'}
                  imgSrc={task.icon}
                  altTxt={'Insta Photo'}
                  rewardTxt={task.text}
                />
              </div>
            </RDiv>
            <QrDiv>
              <QrLogo>
                <img src={require(`assets/${QR}`)} alt="Qr Code" />
              </QrLogo>
              <Typography
                variant={TypographyVariantEnums.PARAGRAPH}
                color="white"
              >
                {QRText}
              </Typography>
            </QrDiv>
            <QrButtonContainer>
              <Button
                variant={ButtonVariantEnums.OUTLINE}
                width="100%"
                to={QRLink}
              >
                Start Challenge
              </Button>
            </QrButtonContainer>
          </TxtDiv>
          <DeskImgDiv>
            <CustomVideoPlayer
              videoSrc={require(`assets/${PreviewVideo}`)}
              play
            />
          </DeskImgDiv>
        </SDiv>
      </Container>
    </Wrapper>
  )
}

export default Content
