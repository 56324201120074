import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  margin-top: 120px;
  padding-inline: 30px;
  justify-content: center;

  @media (max-width: 1024px) {
    width: 95%;
    flex-direction: column;
    gap: 40px;
    padding-inline: 10px;
  }
`

export const QuestificationContent = styled.div<{ isVisible: boolean }>`
  display: flex;
  width: 60%;
  flex-direction: column;
  gap: 21px;

  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  transition: opacity 0.5s ease-in-out;

  @media (max-width: 1024px) {
    margin-top: 90px;
    width: 95%;
    margin-inline: auto;
    opacity: 1;

    button {
      width: 100%;
    }
  }
`

export const HalfDivider = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 21px;

  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const PlatformsWrapper = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(2, 1fr);
`

export const VideoContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  margin-top: 20px;
  height: 100%;

  transform: translate(-60%);
  @media (max-width: 1369px) {
    transform: none;
  }

  @media (max-width: 1024px) {
    width: 95%;
    flex-direction: column-reverse;
    align-items: flex-start;
    div {
      width: 100%;
    }
  }
`
