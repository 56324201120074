import {
  ImageColumn,
  StyledBr,
  Typography,
  TypographyAlignEnums,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'
import {
  ArticleContent,
  StyledImageColumn,
  StyledImageContainer,
  Wrapper,
} from './stylde-components'

const CreateValuableContent = () => {
  return (
    <Wrapper>
      <StyledImageContainer>
        <ImageColumn>
          <img
            src={require('assets/images/createUnder15Min-3.png')}
            alt="createUnder15Min-3"
          />
        </ImageColumn>
        <StyledImageColumn transform={30}>
          <img
            src={require('assets/images/createUnder15Min-4.png')}
            alt="createUnder15Min-4"
          />
        </StyledImageColumn>
      </StyledImageContainer>
      <ArticleContent>
        <Typography
          variant={TypographyVariantEnums.TITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.LEFT}
        >
          Create Valuable and
          <StyledBr />
          Engaging Content
          <StyledBr />
          with Us
        </Typography>
        <Typography
          variant={TypographyVariantEnums.SUBTITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.LEFT}
        >
          With our augmented reality campaigns, you can increase your online
          visibility and connect with your audience like never before
        </Typography>
      </ArticleContent>
    </Wrapper>
  )
}

export default CreateValuableContent
