import {
  Button,
  ButtonVariantEnums,
  Typography,
  TypographyVariantEnums,
} from 'components'
import {
  FooterContainer,
  Responsive,
  ResponsiveLarge,
} from './styled-components'
import { IconsContainer } from 'pages/BrandsLanding/styled-components'

// svgs
import FacebookIcon from 'assets/icons/Facebook.svg'
import InstagramIcon from 'assets/icons/Instagram.svg'

const Footer = () => {
  return (
    <FooterContainer>
      <Typography variant={TypographyVariantEnums.PARAGRAPH} link="#">
        Terms & Conditions
      </Typography>
      <ResponsiveLarge>
        <Typography variant={TypographyVariantEnums.PARAGRAPH} link="#">
          All Rights Reserved
        </Typography>
      </ResponsiveLarge>
      <IconsContainer>
        <Button
          variant={ButtonVariantEnums.ICON}
          onClick={() =>
            window.open('https://www.facebook.com/artttify', '_blank')
          }
        >
          <img src={FacebookIcon} alt="facebook" />
        </Button>

        <Button
          variant={ButtonVariantEnums.ICON}
          onClick={() =>
            window.open('https://www.facebook.com/artttify', '_blank')
          }
        >
          <img src={InstagramIcon} alt="instagram" />
        </Button>
      </IconsContainer>
      <Responsive>
        <Typography variant={TypographyVariantEnums.PARAGRAPH} link="#">
          All Rights Reserved
        </Typography>
      </Responsive>
    </FooterContainer>
  )
}

export default Footer
