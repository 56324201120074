import { Layout } from 'layout'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { AmbassadorsLanding, BrandsLanding } from 'pages'
import { CampaignsLanding } from 'pages/CampainsLanding'

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<BrandsLanding />} />
          <Route path="ambassador" element={<AmbassadorsLanding />} />
          <Route
            path="Ambassador/Campaigns/:campaignUrl"
            element={<CampaignsLanding />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default Router
