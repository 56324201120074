import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  padding: 60px 10%;
  gap: 100px;
  max-width: 1880px;
  width: 100%;
  align-items: center;
  margin: 0 auto;
`

export const Content = styled.article`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 40px;

  @media (max-width: 1300px) {
    gap: 0;
  }
`
export const SideDiv = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 30px;
  width: calc(100% / 6);

  @media (max-width: 1300px) {
    width: calc(100% / 4);
  }

  img {
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1024px) {
    justify-content: center;
    width: 35%;
  }
`

export const MiddleDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;

  img {
    border-radius: 10px;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 1024px) {
    justify-content: center;
  }
`

export const ComparisonTable = styled.div<{ top?: boolean }>`
  display: flex;
  flex-direction: column;
  transform: translateY(80px);

  @media (max-width: 1680px) {
    transform: translateY(30px);
  }

  @media (max-width: 1300px) {
    transform: translateY(50px);
  }

  div:not(:last-child) {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  ${({ top }) =>
    top
      ? `@media (max-width: 1024px) {
    display: none;
  }`
      : `@media (min-width: 1024px) {
    display: none;
  }`}

  @media (max-width: 1024px) {
    width: 100%;
    transform: translateY(0);
  }
`

export const ComparisonRow = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 415px;

  padding: 24px 0;
  position: relative;

  h4 {
    font-size: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 10px;
    color: white;
    font-family: 'Helvetica Neue';
  }

  .progress {
    color: rgba(51, 191, 165, 1);
  }

  .regress {
    color: rgba(255, 0, 0, 1);
  }

  .label {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  @media (max-width: 1300px) {
    gap: 200px;
  }

  @media (max-width: 1024px) {
    gap: 100px;

    h4 {
      font-size: 16px;
    }

    .label {
      font-size: 12px;
    }
  }
`

export const TryYourselfConteiner = styled.div`
  display: flex;
  gap: 15px;
  background: linear-gradient(
    154.04deg,
    rgba(0, 0, 0, 0.9) -0.71%,
    rgba(19, 26, 27, 0.9) 95.15%
  );
  border-radius: 14px;
  align-items: center;
  position: absolute;
  bottom: 10px;
  left: 50%;
  padding: 13px;
  transform: translateX(-50%);
  width: 90%;

  img {
    border-radius: 0 !important;
    width: 40% !important;
  }

  p {
    color: white;
    line-height: 1.5;
    font-weight: 500;
    font-family: 'Nekst R';
  }

  @media (max-width: 1024px) {
    display: none;
  }
`
