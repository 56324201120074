import styled from 'styled-components'

export const Wrapper = styled.section`
  max-width: 1800px;
  width: 100%;
  margin: 88px auto;
  display: flex;
  justify-content: space-between;
  gap: 130px;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 24px;
    margin: 48px auto;

    h2,
    h4 {
      text-align: center;
    }
  }
`

export const ArticleContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  align-items: flex-end;
`
