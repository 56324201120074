import { HalfDiv, Wrapper } from './styled-components'
import {
  Button,
  ButtonVariantEnums,
  Typography,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'

import CheckCircleSvg from 'assets/icons/check-circle.svg'
import { useWaitlistModalContext } from 'context/WaitlistModalContext'
import { GeneratorObjectiveEnums } from 'pages/generator/generator.interface'

const Launch = ({
  objective,
}: {
  objective: GeneratorObjectiveEnums | null
}) => {
  const { setWaitlistModalOpen } = useWaitlistModalContext()

  const handleLaunch = () => {
    setWaitlistModalOpen(true)
  }
  return (
    <Wrapper>
      <img src={CheckCircleSvg} alt="check-circle" />
      <Typography
        variant={TypographyVariantEnums.TITLE}
        size={TypographySizeEnums.LARGE}
      >
        You have successfully launched the Demo Campaign
      </Typography>
      <HalfDiv>
        <Button
          width="100%"
          variant={ButtonVariantEnums.OUTLINE}
          onClick={handleLaunch}
          to={
            objective === GeneratorObjectiveEnums.SCROLL
              ? '/Ambassador/Campaigns/scroll'
              : null
          }
        >
          {objective === GeneratorObjectiveEnums.SCROLL
            ? 'Publish Campaign'
            : 'Launch Your Own'}
        </Button>
      </HalfDiv>
    </Wrapper>
  )
}

export default Launch
