import styled from 'styled-components'

export const PurpleOval = styled.div`
  width: 80%;
  height: 100%;
  position: fixed;
  top: -7vh;
  left: -33vw;
  z-index: -2;
  transform: rotate(313deg);
  flex-shrink: 0;
  border-radius: 1355px;
  background: radial-gradient(
    334.11% 100% at 50.22% -0%,
    rgba(64, 48, 102, 0.7) 0%,
    rgba(151, 100, 225, 0.7) 100%
  );
  filter: blur(232px);
`
export const CyanOval = styled.div`
  width: 100%;
  height: 70%;
  top: 47vh;
  right: 0;
  transform: rotate(-20.725deg);
  flex-shrink: 0;
  position: fixed;
  z-index: -3;
  background: radial-gradient(
    334.11% 100% at 50.22% -0%,
    rgba(62, 255, 216, 0.7) 0%,
    rgba(124, 239, 216, 0.7) 100%
  );
  filter: blur(300px);
`
export const LayoutWrapper = styled.div``

export const PurpleGradient = styled.div<{ top?: boolean }>`
  position: absolute;
  width: 100%;
  height: 70%;
  ${({ top }) => (top ? 'left: -35%;' : 'left: -15%;')}
  ${({ top }) => (top ? 'top: -8%;' : 'bottom: 55%;')}
  z-index: -2;

  background: radial-gradient(
      334.1% 100% at 50.22% 0%,
      rgba(64, 48, 102, 0.7) 0%,
      rgba(151, 100, 225, 0.7) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  filter: blur(232px);
  transform: rotate(-40.1deg);
`

export const CyanGradient = styled.div<{ top?: boolean }>`
  position: absolute;
  width: 106%;
  height: 59%;
  left: -6%;
  right: -50%;
  top: 0%;
  bottom: 55%;
  z-index: -3;
  background: radial-gradient(
      334.1% 100% at 50.22% 0%,
      rgba(62, 255, 216, 0.7) 0%,
      rgba(124, 239, 216, 0.7) 100%
    )
    /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
  filter: blur(300px);
  transform: rotate(-15.35deg);
`

export const WhiteGlowingCircle = styled.div`
  position: fixed;
  width: 18%;
  left: 46%;
  top: 2%;
  z-index: -4;
  aspect-ratio: 1/1;

  background: radial-gradient(
    57.73% 57.73% at 50% 50%,
    #dcccff 0%,
    rgba(88, 255, 245, 0) 79.86%
  );
  filter: blur(50px);
`

// export const BackDrop = styled.div`
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   z-index: -1;
//   background: rgba(0, 0, 0, 0.1);
//   backdrop-filter: blur(30px);
// `
