import {
  ImageColumn,
  Typography,
  TypographyAlignEnums,
  TypographySizeEnums,
  TypographyVariantEnums,
  StyledBr, CustomVideoPlayer,
} from 'components'
import {
  ArticleContent,
  StyledImageColumn,
  StyledImageContainer,
  Wrapper,
} from './stylde-components'

const CollaborateWithBrands = () => {
  return (
    <Wrapper>
      <StyledImageContainer>
        <StyledImageColumn>
          <CustomVideoPlayer
            videoSrc={require('assets/videos/ambassadros_page/2.mov')}
            play
          />
        </StyledImageColumn>
        <ImageColumn transform={30}>
           <CustomVideoPlayer
            videoSrc={require('assets/videos/ambassadros_page/6.mov')}
            play
          />
        </ImageColumn>
      </StyledImageContainer>
      <ArticleContent>
        <Typography
          variant={TypographyVariantEnums.TITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.LEFT}
        >
          Create
          <StyledBr />
          Share
          <StyledBr />
          Redeeem
        </Typography>
        <Typography
          variant={TypographyVariantEnums.SUBTITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.LEFT}
        >
          Create and share user-generated content and
          monetize your online engagment - Get cash,
          <StyledBr />
          gifts, and discounts from your favourite brands.
        </Typography>
      </ArticleContent>
    </Wrapper>
  )
}

export default CollaborateWithBrands
