import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 120px 10%;
  gap: 100px;
  flex-direction: column;
  gap: 50px;
  max-width: 1880px;
  width: 100%;
  align-items: center;
  margin: 0 auto;
`

export const ImagesContainer = styled.article`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 70px;

  @media (max-width: 1440px) {
    display: none;
  }

  img {
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;
  }

  img:hover {
    filter: grayscale(0%);
  }
`
