import {
  // BoostYourPresence,
  Campaigns,
  CollaborateWithBrands,
  // CreateValuableContent,
  InvolveMillions,
} from './sections'
import { ContentContainer } from './styled-components'

const Content = () => {
  return (
    <ContentContainer>
      <Campaigns />
      <InvolveMillions />
      <CollaborateWithBrands />
    </ContentContainer>
  )
}

export default Content
