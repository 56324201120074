import { WaitlistModalProvider, GeneratorProvider } from 'context'
import CssBaseline from 'globalStyles'
import { Router } from 'routes'

const App = () => {
  return (
    <GeneratorProvider>
      <WaitlistModalProvider>
        <CssBaseline />
        <Router />
      </WaitlistModalProvider>
    </GeneratorProvider>
  )
}

export default App
