import { useEffect, useState } from 'react'
import { UserTypeEnums } from 'globalInterfaces'
import { Header } from 'pages'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
  // BackDrop,
  CyanOval,
  LayoutWrapper,
  PurpleOval,
  WhiteGlowingCircle,
} from './styled-components'
import UNDERCONSTRUCTION from './UNDERCONSTRUCTION'

const Layout = () => {
  const [userType, setUserType] = useState<UserTypeEnums>(UserTypeEnums.BRAND)
  const navigate = useNavigate()
  const location = useLocation()

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setUserType(UserTypeEnums.BRAND)
        break
      case '/Ambassador':
        setUserType(UserTypeEnums.AMBASSADOR)
        break
      default:
        setUserType(UserTypeEnums.BRAND)
        break
    }
  }, [location])

  const handleUserTypeChange = (userType: UserTypeEnums) => {
    setUserType(userType)
    navigate(`/${userType === UserTypeEnums.BRAND ? '' : userType}`)
  }

  return (
    <LayoutWrapper>
      <UNDERCONSTRUCTION />
      <Header userType={userType} setUserType={handleUserTypeChange} />
      <PurpleOval />
      <CyanOval />
      <WhiteGlowingCircle />
      <Outlet />
    </LayoutWrapper>
  )
}

export default Layout
