import {
  Button,
  ButtonVariantEnums,
  Typography,
  TypographyVariantEnums,
} from 'components'
import {
  FooterContainer,
  Responsive,
  ResponsiveLarge,
} from './styled-components'
import { IconsContainer } from 'pages/BrandsLanding/styled-components'

// svgs
import LinkedInIcon from 'assets/icons/LinkedIn.svg'

const Footer = () => {
  return (
    <FooterContainer>
      <Typography variant={TypographyVariantEnums.PARAGRAPH} link="#">
        Terms & Conditions
      </Typography>
      <ResponsiveLarge>
        <Typography variant={TypographyVariantEnums.PARAGRAPH} link="#">
          All Rights Reserved
        </Typography>
      </ResponsiveLarge>
      <IconsContainer>
        <Button
          variant={ButtonVariantEnums.ICON}
          onClick={() =>
            window.open('https://ge.linkedin.com/company/artttify', '_blank')
          }
        >
          <img src={LinkedInIcon} alt="linkedin" />
        </Button>
      </IconsContainer>
      <Responsive>
        <Typography variant={TypographyVariantEnums.PARAGRAPH} link="#">
          All Rights Reserved
        </Typography>
      </Responsive>
    </FooterContainer>
  )
}

export default Footer
