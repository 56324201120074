import { ImageWrapper, Wrapper } from './styled-components'
import {
  Typography,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'

const MultipleArCampaigns = () => {
  return (
    <Wrapper>
      <Typography
        variant={TypographyVariantEnums.TITLE}
        size={TypographySizeEnums.LARGE}
      >
        Use analytics to track performance and optimize future campaigns
      </Typography>
      <ImageWrapper full>
        <img
          src={require('assets/images/multipleArCampaigns.png')}
          alt="Admin Panel Preview"
        />
      </ImageWrapper>
      <ImageWrapper>
        <img
          src={require('assets/images/multipleArCampaigns-cropped.png')}
          alt="Admin Panel Preview"
        />
      </ImageWrapper>
    </Wrapper>
  )
}

export default MultipleArCampaigns
