import styled from 'styled-components'
import {
  TypographyAlignEnums,
  TypographySizeEnums,
} from './typography.interface'

export const Title = styled.h2<{
  size: TypographySizeEnums
  align: TypographyAlignEnums
  color?: string
  changeColorOnResponsive?: boolean
}>`
  width: 100%;
  text-wrap: balance;
  font-family: 'Nekst R';
  font-style: normal;
  font-weight: 400;

  font-size: ${({ size }) => {
    switch (size) {
      case TypographySizeEnums.SMALL:
        return '16px'
      case TypographySizeEnums.MEDIUM:
        return '32px'
      case TypographySizeEnums.LARGE:
        return '48px'
      default:
        return '48px'
    }
  }};

  line-height: ${({ size }) => {
    switch (size) {
      case TypographySizeEnums.SMALL:
        return '1'
      case TypographySizeEnums.MEDIUM:
        return '40px'
      case TypographySizeEnums.LARGE:
        return '56px'
      default:
        return '60px'
    }
  }};
  text-align: ${({ align }) => align};
  ${({ color, changeColorOnResponsive }) =>
    changeColorOnResponsive
      ? `
      color: white;
    @media (max-width: 1024px) {
      color: ${color ? color : '#ffffff'};
    }
  `
      : color
      ? `color: ${color}`
      : 'color: #ffffff'};

  span {
    font-family: 'Sans serif';
  }

  @media (max-width: 1024px) {
    font-size: ${({ size }) => {
      switch (size) {
        case TypographySizeEnums.SMALL:
          return '16px'
        case TypographySizeEnums.MEDIUM:
          return '24px'
        case TypographySizeEnums.LARGE:
          return '32px'
        default:
          return '24px'
      }
    }};
    line-height: ${({ size }) => {
      switch (size) {
        case TypographySizeEnums.SMALL:
          return '1'
        case TypographySizeEnums.MEDIUM:
          return '30px'
        case TypographySizeEnums.LARGE:
          return '40px'
        default:
          return '30px'
      }
    }};
  }

  @media (max-width: 768px) {
    font-size: ${({ size }) => {
      switch (size) {
        case TypographySizeEnums.SMALL:
          return '16px'
        case TypographySizeEnums.MEDIUM:
          return '24px'
        case TypographySizeEnums.LARGE:
          return '32px'
        default:
          return '20px'
      }
    }};
    line-height: ${({ size }) => {
      switch (size) {
        case TypographySizeEnums.SMALL:
          return '1'
        case TypographySizeEnums.MEDIUM:
          return '20px'
        case TypographySizeEnums.LARGE:
          return '36px'
        default:
          return '36px'
      }
    }};
  }
`

export const Subtitle = styled.h4<{
  size: TypographySizeEnums
  align: TypographyAlignEnums
  color?: string
}>`
  width: 100%;
  font-family: 'Helvetica Neue';
  font-style: normal;
  text-wrap: balance;
  font-weight: 400;
  line-height: ${({ size }) => {
    switch (size) {
      case TypographySizeEnums.SMALL:
        return '1'
      case TypographySizeEnums.MEDIUM:
        return '30px'
      case TypographySizeEnums.LARGE:
        return '35px'
      default:
        return '30px'
    }
  }};
  font-size: ${({ size }) => {
    switch (size) {
      case TypographySizeEnums.SMALL:
        return '14px'
      case TypographySizeEnums.MEDIUM:
        return '16px'
      case TypographySizeEnums.LARGE:
        return '20px'
      default:
        return '20px'
    }
  }};
  text-align: ${({ align }) => align};
  color: ${({ color }) => (color ? color : '#ffffff70')};

  @media (max-width: 1440px) {
    font-size: ${({ size }) => {
      switch (size) {
        case TypographySizeEnums.SMALL:
          return '14px'
        case TypographySizeEnums.MEDIUM:
          return '14px'
        case TypographySizeEnums.LARGE:
          return '16px'
        default:
          return '16px'
      }
    }};
    line-height: ${({ size }) => {
      switch (size) {
        case TypographySizeEnums.SMALL:
          return '1.2'
        case TypographySizeEnums.MEDIUM:
          return '20px'
        case TypographySizeEnums.LARGE:
          return '25px'
        default:
          return '30px'
      }
    }};
  }

  @media (max-width: 1024px) {
    font-size: ${({ size }) => {
      switch (size) {
        case TypographySizeEnums.SMALL:
          return '14px'
        case TypographySizeEnums.MEDIUM:
          return '14px'
        case TypographySizeEnums.LARGE:
          return '14px'
        default:
          return '14px'
      }
    }};
    line-height: ${({ size }) => {
      switch (size) {
        case TypographySizeEnums.SMALL:
          return '1.1'
        case TypographySizeEnums.MEDIUM:
          return '24px'
        case TypographySizeEnums.LARGE:
          return '32px'
        default:
          return '20px'
      }
    }};
  }

  @media (max-width: 768px) {
    font-size: ${({ size }) => {
      switch (size) {
        case TypographySizeEnums.SMALL:
          return '14px'
        case TypographySizeEnums.MEDIUM:
          return '14px'
        case TypographySizeEnums.LARGE:
          return '14px'
        default:
          return '14px'
      }
    }};

    line-height: ${({ size }) => {
      switch (size) {
        case TypographySizeEnums.SMALL:
          return '1'
        case TypographySizeEnums.MEDIUM:
          return '1'
        case TypographySizeEnums.LARGE:
          return '18px'
        default:
          return '1'
      }
    }};
  }
`

export const Paragraph = styled.p<{
  size: TypographySizeEnums
  align: TypographyAlignEnums
  color?: string
}>`
  font-family: 'Helvetica Neue';
  font-size: 16px;
  font-style: normal;
  text-wrap: balance;
  font-weight: 400;
  text-align: ${({ align }) => align};
  color: ${({ color }) => color || '#ffffff90'};

  line-height: ${({ size }) => {
    switch (size) {
      case TypographySizeEnums.SMALL:
        return '1.1'
      case TypographySizeEnums.MEDIUM:
        return '1.2'
      case TypographySizeEnums.LARGE:
        return '1.3'
      default:
        return '1.3'
    }
  }};
`
