import {
  Typography,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'
import { StepperProps } from './stepper.interface'
import {
  ProgressLine,
  Step,
  StepperWrapper,
  HorizontalDottedLine,
  StepperWrapperMobile,
} from './styled-components'

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const Stepper = ({ activeStep, setActiveStep, steps }: StepperProps) => {
  const activeStepIndex = steps.indexOf(activeStep)

  return (
    <>
      <StepperWrapper>
        {steps.map((step, currentStepIndex) => (
          <div key={currentStepIndex}>
            <Step
              active={steps.indexOf(activeStep) >= currentStepIndex}
              label={step}
              clickable={activeStepIndex >= currentStepIndex}
              onClick={() =>
                activeStepIndex >= currentStepIndex && setActiveStep(step)
              }
              index={currentStepIndex}
            >
              {currentStepIndex < steps.length - 1 && <HorizontalDottedLine />}
            </Step>
          </div>
        ))}
        <ProgressLine activeStepIndex={activeStepIndex} />
      </StepperWrapper>
      <StepperWrapperMobile>
        <Typography
          variant={TypographyVariantEnums.PARAGRAPH}
          size={TypographySizeEnums.SMALL}
        >
          {activeStep}
        </Typography>
        <CircularProgressbar
          value={(activeStepIndex + 1) * 25}
          styles={buildStyles({
            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: 'butt',
            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,

            // Colors
            pathColor: `white`,
            textColor: 'white',
            trailColor: 'rgba(255,255,255, 0.2)',
            backgroundColor: 'rgba(255,255,255, 0.2)',
          })}
        />
      </StepperWrapperMobile>
    </>
  )
}

export default Stepper
