import { MainContainer } from './styled-components'
import { BannerSection, Content } from './content'
import Footer from './footer/Footer'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const AmbassadorsLanding = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  return (
    <>
      <MainContainer>
        <BannerSection />
        <Content />
        <Footer />
      </MainContainer>
    </>
  )
}

export default AmbassadorsLanding
