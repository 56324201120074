import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { CarouselItem, StyledSlider } from './styled-components'
import { Settings } from 'react-slick'

const Carousel = ({ data }: { data: string[] }) => {
  const settings: Settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  }

  return (
    <StyledSlider {...settings}>
      {data.map((item, index) => (
        <CarouselItem>
          <img src={item} key={index} alt={item + index} />
        </CarouselItem>
      ))}
    </StyledSlider>
  )
}

export default Carousel
