import styled from 'styled-components'

export const SelectWrapper = styled.div`
  position: relative;
`

export const StyledSelect = styled.select`
  border: 1px solid #ccc;
  border-radius: 30px;
  width: 100%;
  color: white;
  padding: 21px;
  outline: none;
  background: transparent;
`

export const Dropdown = styled.ul`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 99999;
  list-style: none;
  padding: 0;
  margin: 0;
  width: 100%;
  background: rgba(41, 38, 43);
  border: 1px solid #ccc;
  border-radius: 15px;
  display: ${(props: { isOpen: boolean }) => (props.isOpen ? 'block' : 'none')};
`

export const Option = styled.li`
  padding: 10px;
  cursor: pointer;
  border-radius: 13px;
  transition: all 0.2s ease-in-out;
  color: white;
  font-family: 'Helvetica';
  background-color: transparent;
  &:hover {
    color: rgba(41, 38, 43);
    background-color: #f0f0f0;
  }
`

export const customStyles = {
  control: (provided: any) => ({
    ...provided,
    padding: '11px',
    width: '100%',
    border: '1px solid #ccc',
    borderRadius: '30px',
    backgroundColor: 'transparent',
    outline: 'none',
    color: 'white',
    cursor: 'pointer',

    '&:focus': {
      border: '1px solid #ccc',
      outline: 'none',
    },
    '&:hover': {
      border: '1px solid #ccc',
      outline: 'none',
    },
    '& svg': {
      color: 'rgba(255, 255, 255, 0.6)',

      '&:hover, &:focus': {
        color: 'rgba(255, 255, 255, 1)',
      },
    },
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    cursor: 'pointer',
    borderRadius: '13px',
    color: 'rgba(41, 38, 43)',
    outline: 'none',
    transition: 'all 0.2s ease-in-out',
    fontFamily: 'Helvetica',
    backgroundColor: state.isFocused ? '#f0f0f0' : 'transparent',
    '&:hover': {
      color: 'rgba(41, 38, 43)',
      backgroundColor: '#f0f0f0',
    },

    '& > .label': {
      display: 'none',
    },
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: 'white',
    fontFamily: 'Helvetica',
  }),
  placeholder: (provided: any) => ({
    ...provided,
    color: 'white',
    fontFamily: 'Helvetica',
  }),
}
