import {
  Typography,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'
import { Wrapper } from './styled-components'
import Carousel from './Carousel'

const dummyData = [
  {
    description:
      '“Our clients loved participating in ARtttify’s AR challenge campaigns. We saw an incredible 68% increase in repeat hotel bookings."',
    author: {
      name: 'Teona Mshvidobadze',
      position: 'CMO at Resolute Hotel & Resort',
      image: require('assets/images/testimonials/tenoa.jpg'),
    },
  },
  {
    description:
      '“We were struggling to activate our customers for quite some time. Initially, we engaged influencers, but rewarding customers brought way better results compared to relying  on individual influencers."',
    author: {
      name: 'Levan Nibladze',
      position: 'Marketing Director at Frutiffy',
      image: require('assets/images/whatPeopleThinks.png'),
    },
  },
  {
    description:
      '“ARtttify is my team\'s new discovery! We were doing challenge campaigns for virality but it was hard to track. Having all the tools in one place makes life so much easier."',
    author: {
      name: 'Keti Bolkvadze',
      position: 'CEO at Deseo',
      image: require('assets/images/testimonials/keti.jpg'),
    },
  },
  {
    description:
      '“Our clients loved participating in ARtttify’s AR challenge campaigns. We saw an incredible 68% increase in repeat hotel bookings."',
    author: {
      name: 'Teona Mshvidobadze',
      position: 'CMO at Resolute Hotel & Resort',
      image: require('assets/images/testimonials/keti.jpg'),
    },
  },
  {
    description:
      '“We were struggling to activate our customers for quite some time. Initially, we engaged influencers, but rewarding customers brought way better results compared to relying  on individual influencers."',
    author: {
      name: 'Levan Nibladze',
      position: 'Marketing Director at Frutiffy',
      image: require('assets/images/whatPeopleThinks.png'),
    },
  },
  {
    description:
      '“ARtttify is my team\'s new discovery! We were doing challenge campaigns for virality but it was hard to track. Having all the tools in one place makes life so much easier."',
    author: {
      name: 'Keti Bolkvadze',
      position: 'CEO at Deseo',
      image: require('assets/images/testimonials/tenoa.jpg'),
    },
  },
]

const WhatThePeopleThinkAboutUs = () => {
  return (
    <Wrapper>
      <Typography
        variant={TypographyVariantEnums.TITLE}
        size={TypographySizeEnums.LARGE}
      >
        What People Think About Us
      </Typography>
      <Carousel data={dummyData} />
    </Wrapper>
  )
}

export default WhatThePeopleThinkAboutUs
