import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import { StyledSlider } from './styled-components'
import { Settings } from 'react-slick'
import { useEffect, useState } from 'react'
import dummyData from './DummyData'
import { CustomVideoPlayer } from '../../../../../../components'

const Carousel = () => {
  const [centerIndex, setCenterIndex] = useState(0)

  const handleAfterChange = (currentIndex: number) => {
    setCenterIndex(currentIndex)
  }

  useEffect(() => {
    const updateSlideClasses = () => {
      const slideElements = document.querySelectorAll('.carousel-item')

      slideElements.forEach((slideElement, index) => {
        const activeSlideIndex = centerIndex + 6
        const distance = Math.abs(activeSlideIndex - index)

        slideElement.classList.remove(
          'distance-one',
          'distance-two',
          'distance-three',
          'distance-four',
          'distance-five',
          'right',
          'left',
        )

        const getDistanceClassName = (distance: number) => {
          while (distance > 6) distance -= 6

          const direction = index > activeSlideIndex ? 'right' : 'left'

          const classNames = []
          if (distance) classNames.push(direction)

          if (distance === 1) {
            classNames.push('distance-one')
          } else if (distance === 2) {
            classNames.push('distance-two')
          } else if (distance === 3) {
            classNames.push('distance-three')
          } else if (distance === 4) {
            classNames.push('distance-four')
          } else if (distance === 5) {
            classNames.push('distance-five')
          } else if (distance === 6) {
            classNames.push('distance-five')
          }

          return classNames
        }

        const distanceClassNames = getDistanceClassName(distance)
        if (distanceClassNames)
          distanceClassNames.forEach((className) =>
            slideElement.classList.add(className),
          )
      })
    }

    updateSlideClasses()
    window.addEventListener('resize', updateSlideClasses)
    return () => {
      window.removeEventListener('resize', updateSlideClasses)
    }
  }, [centerIndex])

  const settings: Settings = {
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToShow: 5,
    slidesToScroll: 1,
    centerMode: true,
    arrows: false,
    centerPadding: '0px',
    afterChange: handleAfterChange,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  return (
    <StyledSlider {...settings}>
      {dummyData.map((item, index) => (
        <div className="carousel-item" key={index}>
          <CustomVideoPlayer videoSrc={item.img} play />
        </div>
      ))}
    </StyledSlider>
  )
}

export default Carousel
