import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 60px 0;
  position: relative;
  gap: 100px;
  flex-direction: column;
  gap: 50px;
  width: 100%;
  max-width: 1880px;
  align-items: center;
  margin: 0 auto;
`
