import {
  Button,
  ButtonVariantEnums,
  Typography,
  TypographyAlignEnums,
  TypographySizeEnums,
  TypographyVariantEnums,
  ImageColumn,
  ImagesContainer, CustomVideoPlayer,
} from 'components'
import { TextContainer, Wrapper } from './styled-components'
import { PopupButton } from 'react-calendly'

const CreateArUnder15Min = () => {
  return (
    <Wrapper>
      <TextContainer>
        <Typography
          variant={TypographyVariantEnums.TITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.LEFT}
        >
          Turn marketing objectives into <br />
          engaging campaigns that sell<span>!</span>
        </Typography>
        <Typography
          variant={TypographyVariantEnums.SUBTITLE}
          size={TypographySizeEnums.MEDIUM}
          align={TypographyAlignEnums.LEFT}
        >
          Captivate your audience with immersive AR experiences that not only
          capture attention but also create lasting connections. You don't need
          to be an AR expert – ARtttify does the heavy lifting for you.
        </Typography>
        <div>
          <Button variant={ButtonVariantEnums.FILL}>
            <PopupButton
              url="https://calendly.com/mariam-artttify"
              /*
               * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
               * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
               */
              rootElement={document.getElementById('root')!} // TODO: type the right way instead of using !
              text="Get Started"
              styles={{ color: '#fff', fontWeight: 'bold' }} // TODO: Styles might not be here
            />
          </Button>
        </div>
      </TextContainer>
      <ImagesContainer>
        <ImageColumn>
          <CustomVideoPlayer
            videoSrc={require('assets/videos/brand_page/1.mov')}
            play
          />
          <CustomVideoPlayer
            videoSrc={require('assets/videos/brand_page/3.mov')}
            play
          />
        </ImageColumn>
        <ImageColumn transform={30}>
          <CustomVideoPlayer
            videoSrc={require('assets/videos/brand_page/5.mov')}
            play
          />
          <CustomVideoPlayer
            videoSrc={require('assets/videos/brand_page/7.MP4')}
            play
          />
        </ImageColumn>
      </ImagesContainer>
    </Wrapper>
  )
}

export default CreateArUnder15Min
