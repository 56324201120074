import styled from 'styled-components'

export const RDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 1300px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 20px;
  }
`
export const RewardDiv = styled.div`
  @media screen and (width > 1300px) {
    order: 1;
  }
`

export const DeskImgDiv = styled.div`
  display: flex;
  width: 400px;
  justify-content: center;
  align-items: center;

  @media (max-width: 1300px) {
    margin-top: 40px;
    width: 100%;
  }
`

export const Img = styled.img`
  border-radius: 10px;
`

export const Wrapper = styled.div`
  margin-top: 130px;
  @media screen and (width > 1300px) {
    display: flex;
    justify-content: end;
    align-items: center;
  }
`
export const Container = styled.div`
  width: 90%;
  border-radius: 24px 0px 0px 24px;
  padding: 40px;
  background-color: black;
  @media (max-width: 1300px) {
    margin-inline: auto;
    border-radius: 24px 24px 0px 0px;
  }
`

export const SDiv = styled.div`
  @media (width > 1300px) {
    display: flex;
    justify-content: space-between;
  }
`

export const TxtDiv = styled.div`
  @media (width > 1300px) {
    width: 60%;
  }
`

export const TxtWrapper = styled.div`
  margin-block: 24px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  @media screen and (width > 1300px) {
    margin-top: 30px;
  }
`

export const HDiv = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
`

export const LogoDiv = styled.div`
  width: 49px;
  height: 49px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  @media screen and (width > 1300px) {
    width: 56px;
    height: 56px;
  }

  img {
    width: 100%;
    height: 100%;
  }
`

export const QrDiv = styled.div`
  margin-top: 80px;
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: 1300px) {
    display: none;
  }
`

export const QrButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding: 20px 10% 5% 10%;
  z-index: 9999;
  position: fixed;
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 0.8827906162464986) 1%,
    rgba(0, 0, 0, 0.7875525210084033) 22%,
    rgba(0, 0, 0, 0.4290091036414566) 62%,
    rgba(0, 0, 0, 0.17130602240896353) 82%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100%;
  left: 0;
  bottom: 0;

  @media (min-width: 1300px) {
    display: none;
  }
`

export const QrLogo = styled.div`
  min-width: 132px;
  height: 132px;
  border-radius: 20px;
  background-color: #e8ebf4;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    height: 100%;
    width: 100%;
  }
`
