import {
  Typography,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'
import { ImagesContainer, Wrapper } from './styled-components'
import Carousel from './Carousel'

const PARTNERS = [
  require('assets/brands/deso.png'),
  require('assets/brands/glenmor.png'),
  require('assets/brands/mulberry.png'),
  require('assets/brands/ჰაბვეი.png'),
  require('assets/brands/yoga rooms.png'),
]

const BrandsWhoTrustUs = () => {
  return (
    <Wrapper>
      <Typography
        variant={TypographyVariantEnums.TITLE}
        size={TypographySizeEnums.LARGE}
      >
        Brands Who Trust Us
      </Typography>
      <ImagesContainer>
        {PARTNERS.map((item, index) => (
          <img src={item} key={index} alt={item + index} />
        ))}
      </ImagesContainer>
      <Carousel data={PARTNERS} />
    </Wrapper>
  )
}

export default BrandsWhoTrustUs
