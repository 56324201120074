import styled, { CSSProperties } from 'styled-components'

export const HeaderContainer = styled.header<{ scrolled: boolean }>`
  display: flex;
  justify-content: space-between;
  padding: 36px 144px;
  height: 123px;
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100vw;
  align-items: center;
  @media (max-width: 1024px) {
    padding: 36px 24px;
  }

  :after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    transition: 1s all;
    opacity: ${({ scrolled }) => (scrolled ? 1 : 0)};
    background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 1) 1%,
      rgba(0, 0, 0, 0.85) 16%,
      rgba(0, 0, 0, 0.55) 62%,
      rgba(0, 0, 0, 0.3) 82%,
      rgba(0, 0, 0, 0) 100%
    );
  }
`
export const Introduction = styled.div`
  display: flex;
  gap: 17px;
  align-items: center;
`
export const MoreDetails = styled.div`
  display: flex;
  gap: 17px;
  align-items: center;
`
export const UserTypes = styled.div`
  display: flex;
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 3px;

  @media (max-width: 1024px) {
    display: none;
  }
`

export const UserTypesMobile = styled.div`
  border-radius: 20px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 3px;
  display: none;

  @media (max-width: 1024px) {
    display: flex;
    position: fixed;
    top: 126px;
    left: 20px;
    z-index: 2;

    button {
      font-size: 14px;
    }
  }
`
export const UserType = styled.button<{ active: boolean }>`
  border-radius: 20px;
  font-size: 16px;
  background-color: ${({ active }) =>
    active ? 'rgba(255, 255, 255, 0.1)' : 'transparent'};
  color: ${({ active }) => (active ? '#ffffff' : '#ffffff60')};
  padding: 8px 16px;
`

export const CalendlyButtonStyles: CSSProperties = {
  color: '#fff',
  fontFamily: 'Nekst R',
  fontSize: '16px',
}
