import React, { useState, useRef, useEffect } from 'react'
import { VideoContainer, VideoPlayer } from './styled-components'

interface CustomVideoPlayerProps {
  videoSrc: string
  play: boolean
  styles?: React.CSSProperties
}

const CustomVideoPlayer: React.FC<CustomVideoPlayerProps> = ({
  play = true,
  videoSrc,
  styles,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const handleVideoClick = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause()
      } else {
        videoRef.current.play()
      }
      return setIsPlaying(!isPlaying)
    }
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [videoSrc])

  // useEffect(() => {
  //   if (play && videoRef.current) {
  //     videoRef.current.play()
  //   }
  // }, [play])

  return (
    <VideoContainer onClick={handleVideoClick}>
      <VideoPlayer
        ref={videoRef}
        controls={false}
        autoPlay
        loop
        muted
        style={styles}
      >
        <source src={videoSrc} type="video/mp4" />
      </VideoPlayer>
    </VideoContainer>
  )
}

export default CustomVideoPlayer
