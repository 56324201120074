import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  justify-content: space-between;
  padding: 60px 10%;
  gap: 100px;
  max-width: 1880px;
  align-items: center;
  margin: 0 auto;

  @media (max-width: 1024px) {
    flex-direction: column;
    padding: 56px 48px;
  }
`

export const TextContainer = styled.article`
  display: flex;
  flex-direction: column;
  width: 50%;
  gap: 30px;

  @media (max-width: 1024px) {
    width: 90%;
    align-items: center;

    h2,
    h4 {
      text-align: center;
    }
  }
`
