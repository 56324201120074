import { ModalContainer } from './styled-components'

const WaitlistModal = ({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}) => {
  return (
    <ModalContainer
      isOpen={isOpen}
      onClick={() => {
        setIsOpen(false)
      }}
    >
      <iframe
        onClick={(e) => {
          e.stopPropagation()
        }}
        width="540"
        title="Join-Us-Form"
        height="700"
        src="https://8cba45d5.sibforms.com/serve/MUIFAGB1tcfxIue6jbxMm9BvlvsPMBur3yz_4mZ9VnmHTZ9mxYdU-YW30oywY2SHOb8GoyUzQPilJu9Upb6xPlGEz44L_XGqvSa6QxcyS3XlNnD2iWaJP31OCNWszctfTJECsgexHlub2uz2Dq5ynhfY_cA6UbPkyRj59mfL7N5Y0CBGWtX8w8Bo8p6ie9prZ9-bCjcL_Vxyjvon"
      ></iframe>
    </ModalContainer>
  )
}

export default WaitlistModal
