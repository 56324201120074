import styled from 'styled-components'

export const Wrapper = styled.section`
  display: flex;
  gap: 40px;
  max-width: 1800px;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;
  margin: 0 auto 80px auto;

  article {
    width: calc(50% - 20px);
  }

  @media (max-width: 1200px) {
    article {
      width: calc(100% - 20px);
    }
  }
`
