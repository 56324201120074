import {
  GeneratorContainer,
  StyledMotionStep,
  tabVariants,
} from './styled-components'
import { useEffect, useState } from 'react'
import { GeneratorChoices, GeneratorStepEnums } from './generator.interface'
import { ARGeneration, Ideation, Launch, Questification } from './steps'
import Stepper from './stepper/Stepper'
import { useGeneratorContext } from 'context/GeneratorContext'

const ArtifyGenerator = () => {
  const { updateCurrentStep } = useGeneratorContext()
  const [step, setStep] = useState<GeneratorStepEnums>(
    GeneratorStepEnums.IDEATION,
  )

  const steps = [
    GeneratorStepEnums.IDEATION,
    GeneratorStepEnums.ARGENERATION,
    GeneratorStepEnums.QUESTIFICATION,
    GeneratorStepEnums.LAUNCH,
  ]

  const [choices, setChoices] = useState<GeneratorChoices>({
    variant: null,
    objective: null,
  })

  useEffect(() => {
    if (updateCurrentStep) updateCurrentStep(step)
  }, [step, updateCurrentStep])

  return (
    <GeneratorContainer currentGeneratorStep={step}>
      <Stepper activeStep={step} setActiveStep={setStep} steps={steps} />
      <StyledMotionStep
        variants={tabVariants}
        initial="open"
        animate={
          step === GeneratorStepEnums.IDEATION
            ? 'open'
            : steps.indexOf(step) > steps.indexOf(GeneratorStepEnums.IDEATION)
            ? 'closed'
            : 'notOpened'
        }
      >
        <Ideation
          choices={choices}
          setChoices={setChoices}
          setGeneratorStep={setStep}
        />
      </StyledMotionStep>
      <StyledMotionStep
        variants={tabVariants}
        initial="notOpened"
        animate={
          step === GeneratorStepEnums.ARGENERATION
            ? 'open'
            : step.indexOf(step) >
              steps.indexOf(GeneratorStepEnums.ARGENERATION)
            ? 'closed'
            : 'notOpened'
        }
      >
        <ARGeneration setGeneratorStep={setStep} generatorStep={step} />
      </StyledMotionStep>
      <StyledMotionStep
        variants={tabVariants}
        initial="notOpened"
        questificationStep={step === GeneratorStepEnums.QUESTIFICATION}
        style={{
          top: 120,
        }}
        animate={
          step === GeneratorStepEnums.QUESTIFICATION
            ? 'open'
            : step.indexOf(step) >
              steps.indexOf(GeneratorStepEnums.QUESTIFICATION)
            ? 'closed'
            : 'notOpened'
        }
      >
        <Questification
          choices={choices}
          generatorStep={step}
          setGeneratorStep={setStep}
        />
      </StyledMotionStep>
      <StyledMotionStep
        variants={tabVariants}
        initial="notOpened"
        animate={
          step === GeneratorStepEnums.LAUNCH
            ? 'open'
            : step.indexOf(step) > steps.indexOf(GeneratorStepEnums.LAUNCH)
            ? 'closed'
            : 'notOpened'
        }
      >
        <Launch objective={choices.objective} />
      </StyledMotionStep>
    </GeneratorContainer>
  )
}

export default ArtifyGenerator
