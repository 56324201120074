import styled from 'styled-components'

export const Container = styled.div<{
  width?: number
  vertical?: boolean
}>`
  display: flex;
  width: ${({ width }) => `calc(${width}% - 30px)` || '100%'};
  flex-direction: ${({ vertical }) => (vertical ? 'column-reverse' : 'row')};
  border-radius: 24px;
  background: linear-gradient(
    141deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(19, 26, 27, 0.14) 100%
  );
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  box-shadow: 0px 4px 50px 0px rgba(0, 0, 0, 0.5);
  padding: 30px;

  @media (max-width: 1024px) {
    width: 100% !important;
    flex-direction: column-reverse;
  }
`
export const ContentWrapper = styled.div<{ width: number }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  width: ${({ width }) => `${width}%`};
  @media (max-width: 1024px) {
    width: 100% !important;
    align-items: center;
  }
`

export const ImageContainer = styled.div<{ width: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ width }) => `${width}%`};

  img {
    width: 100%;
  }
`
