import { useLocation, useParams } from 'react-router-dom'
import Content from './content'
import { useEffect } from 'react'
import dummyData from './dummyData'

const CampaignsLanding = () => {
  const location = useLocation()
  const { campaignUrl } = useParams()

  const currentCampaign = dummyData.find(
    (campaign) => campaign.url === campaignUrl,
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])

  if (!currentCampaign) {
    return null
  }

  return (
    <>
      <Content campaign={currentCampaign} />
    </>
  )
}

export default CampaignsLanding
