import styled from 'styled-components'
import { GeneratorStepEnums } from '../generator.interface'

export const StepperWrapper = styled.div`
  display: flex;
  gap: 120px;
  z-index: 9999;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 20%;

  @media (max-width: 1024px) {
    display: none;
  }

  @media (max-height: 1440px) {
    top: 15%;
  }
`

export const StepperWrapperMobile = styled.div`
  display: none;
  position: absolute;
  right: 20px;
  transform: translateX(-50%);
  top: 120px;
  width: 50px;
  aspect-ratio: 1/1;
  align-items: center;

  p {
    position: absolute;
    left: -10px;
    top: 50%;
    transform: translate(-100%, -50%);
  }

  @media (max-width: 1024px) {
    display: block;
  }
`

export const Step = styled.div<{
  active: boolean
  clickable: boolean
  label: GeneratorStepEnums
  index: number
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  font-family: 'Helvetica neue', sans-serif;
  aspect-ratio: 1/1;
  position: relative;
  font-size: 14px;
  z-index: 2;
  position: relative;
  border: 1px solid;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? '#ffffff' : '#ffffff30')};
  color: ${({ active }) => (active ? '#000000' : '#ffffff60')};
  cursor: ${({ clickable }) => (clickable ? 'pointer' : 'default')};
  border-color: ${({ active }) => (active ? '#ffffff' : '#ffffff30')};

  ::after {
    content: '${({ label }) => label}';
    position: absolute;
    font-family: 'Helvetica neue', sans-serif;
    color: ${({ active }) => (active ? '#ffffff' : '#ffffff30')};
    top: calc(100% + 15px);
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;

    @media (max-width: 1024px) {
      ${({ index }) =>
        index % 2 === 0
          ? 'top: calc(100% + 15px); bottom:auto;'
          : 'bottom: calc(100% + 15px); top:auto;'}
    }
  }
`

export const HorizontalDottedLine = styled.div`
  width: 119px;
  height: 2px;
  position: absolute;
  left: calc(100% + 1px);
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  border-top: 1px dotted #ffffff50;

  @media (max-width: 1024px) {
    width: 60px;
  }
`

export const ProgressLine = styled.div<{
  activeStepIndex: number
}>`
  width: ${({ activeStepIndex }) => `${activeStepIndex * 132}px`};
  height: 2px;
  position: absolute;
  background-color: transparent;
  left: 0;
  transition: width 0.5s ease-in-out;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  border-top: 2px solid #ffffff;

  @media (max-width: 1024px) {
    width: ${({ activeStepIndex }) => `${activeStepIndex * 60}px`};
  }
`
