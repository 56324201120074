import { useGeneratorContext } from 'context/GeneratorContext'
import {
  ArIsMoreEffective,
  BrandsWhoTrustUs,
  CreateArUnder15Min,
  MultipleArCampaigns,
  Showcases,
  WhatThePeopleThinkAboutUs,
  WhyChooseArtttify,
} from './sections'

import { ContentContainer } from './styled-components'

const Content = () => {
  const { currentStep } = useGeneratorContext()
  return (
    <ContentContainer currentGeneratorStep={currentStep}>
      <CreateArUnder15Min />
      <WhyChooseArtttify />
      <ArIsMoreEffective />
      <MultipleArCampaigns />
      <Showcases />
      <BrandsWhoTrustUs />
      <WhatThePeopleThinkAboutUs />
    </ContentContainer>
  )
}

export default Content
