import styled from 'styled-components'

export const ModalContainer = styled.div<{
  isOpen: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  opacity: ${({ isOpen }) => (isOpen ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  z-index: ${({ isOpen }) => (isOpen ? 9999 : -1)};
  background-color: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);

  iframe {
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 100%;
  }

  :after {
    content: 'X';
    position: absolute;
    top: 30px;
    right: 50px;
    font-size: 24px;
    cursor: pointer;
    color: #ffffff;
    font-family: 'Helvetica', sans-serif;
  }
`
