import { Container, ContentWrapper, ImageContainer } from './styled-components'
import {
  Button,
  ButtonVariantEnums,
  Typography,
  TypographyAlignEnums,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'

const Article = ({
  title,
  subtitle,
  image,
  vertical = false,
  width,
  button,
  leftWidth,
  rightWidth,
}: {
  title: string
  subtitle: string
  image: string
  vertical?: boolean
  width: number
  button?: {
    content: string
    link: string
  }
  leftWidth: number
  rightWidth: number
}) => {
  return (
    <Container width={width} vertical={vertical}>
      <ContentWrapper width={leftWidth}>
        <Typography
          variant={TypographyVariantEnums.TITLE}
          size={TypographySizeEnums.MEDIUM}
          align={TypographyAlignEnums.LEFT}
        >
          {title}
        </Typography>
        <Typography
          variant={TypographyVariantEnums.SUBTITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.LEFT}
        >
          {subtitle}
        </Typography>
        {button && (
          <span
            style={{
              marginTop: '1rem',
            }}
          >
            <Button variant={ButtonVariantEnums.FILL} to={button.link}>
              {button.content}
            </Button>
          </span>
        )}
      </ContentWrapper>
      <ImageContainer width={rightWidth}>
        <img src={image} alt={title} />
      </ImageContainer>
    </Container>
  )
}

export default Article
