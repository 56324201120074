import { motion } from 'framer-motion'
import styled from 'styled-components'
import { GeneratorStepEnums } from './generator.interface'

export const GeneratorContainer = styled.div<{
  currentGeneratorStep: GeneratorStepEnums | null
}>`
  width: 100vw;
  height: 95vh;
  top: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  position: fixed;
  transition: all 0.3s ease-in-out;
  justify-content: center;
  padding-inline: 20px;

  ${({ currentGeneratorStep }) =>
    currentGeneratorStep === GeneratorStepEnums.QUESTIFICATION &&
    `
@media (max-width: 1024px) {
 height: 165vh; 
 position: absolute;
}
`}
`

export const StyledMotionStep = styled(motion.div)<{
  questificationStep?: boolean
}>`
  position: absolute;

  ${({ questificationStep }) =>
    questificationStep &&
    `@media (max-width: 1024px) {
    top: 8%;
  }`}
`

export const tabVariants = {
  notOpened: {
    x: 940,
    opacity: 0,
    scale: 0.9,
    transition: {
      x: {
        stiffness: 4000,
        velocity: -100,
        ease: 'easeInOut',
        delay: 1,
        duration: 0.5,
      },
      opacity: { duration: 0.3, ease: 'easeInOut', delay: 0.3 },
      scale: { duration: 0.3, ease: 'easeInOut', delay: 0.5 },
    },
  },
  open: {
    x: 0,
    opacity: 1,
    scale: 1,
    zIndex: 999,
    transition: {
      x: {
        stiffness: 4000,
        velocity: -100,
        duration: 0.5,
        delay: 0.3,
        ease: 'easeInOut',
      },
      opacity: { duration: 0.5, ease: 'easeInOut', delay: 0.3 },
      scale: { duration: 0.5, ease: 'easeInOut', delay: 0.5 },
    },
  },
  closed: {
    x: -940,
    opacity: 0,
    scale: 0.9,
    transition: {
      x: { stiffness: 4000, velocity: -100, ease: 'easeInOut', delay: 0.3 },
      opacity: { duration: 0.3, ease: 'easeInOut', delay: 0.1 },
      scale: { duration: 0.3, ease: 'easeInOut' },
    },
  },
}
