import { Link } from 'react-router-dom'
import { Title } from './styled-components'

const Artttify = () => {
  return (
    <Link to="/">
      <Title>ARtttify</Title>
    </Link>
  )
}

export default Artttify
