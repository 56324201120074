import { Wrapper } from './styled-components'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import {
  Typography,
  TypographySizeEnums,
  TypographyVariantEnums,
  StyledBr,
} from 'components'

import Carousel from './Carousel'
import CarouselMobile from './CarouselMobile'

const Showcases = () => {
  return (
    <Wrapper>
      <Typography
        variant={TypographyVariantEnums.TITLE}
        size={TypographySizeEnums.LARGE}
      >
        150<span>+</span> successful campaigns done <StyledBr />
        {/*through ARtttify*/}
      </Typography>
      <Carousel />
      <CarouselMobile />
    </Wrapper>
  )
}

export default Showcases
