import styled from 'styled-components'

export const Wrapper = styled.div`
  width: 600px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 28px;

  @media (max-width: 768px) {
    width: 490px;

    button {
      width: 100%;
    }
  }

  @media (max-width: 490px) {
    width: 320px;
  }
`

export const HalfDiv = styled.div`
  width: 50%;
`
