import { Paragraph, Subtitle, Title } from './styled-components'
import {
  TypographyAlignEnums,
  TypographyProps,
  TypographySizeEnums,
  TypographyVariantEnums,
} from './typography.interface'

const Typography = ({
  variant = TypographyVariantEnums.TITLE,
  children,
  size = TypographySizeEnums.LARGE,
  align = TypographyAlignEnums.CENTER,
  link,
  color,
  changeColorOnResponsive = false,
}: TypographyProps) => {
  const renderTypography = () => {
    switch (variant) {
      case TypographyVariantEnums.TITLE:
        return (
          <Title
            size={size}
            align={align}
            color={color}
            changeColorOnResponsive={changeColorOnResponsive}
          >
            {children}
          </Title>
        )
      case TypographyVariantEnums.SUBTITLE:
        return (
          <Subtitle size={size} align={align} color={color}>
            {children}
          </Subtitle>
        )
      case TypographyVariantEnums.PARAGRAPH:
        return (
          <Paragraph size={size} align={align} color={color}>
            {children}
          </Paragraph>
        )
      default:
        return (
          <Title size={size} align={align} color={color}>
            {children}
          </Title>
        )
    }
  }

  if (link) {
    return <a href={link}>{renderTypography()}</a>
  }

  return renderTypography()
}

export default Typography
