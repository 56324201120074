import styled from 'styled-components'

export const FooterContainer = styled.footer`
  border-top: 1px solid #ffffff20;
  z-index: 6;
  bottom: 0;
  display: flex;
  position: relative;
  z-index: 9999;
  justify-content: space-between;
  padding: 36px 144px;
  width: 100%;

  @media (max-width: 1024px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    padding: 36px 24px;
  }
`

export const ResponsiveLarge = styled.div`
  @media (max-width: 1024px) {
    display: none;
  }
`
export const Responsive = styled.div`
  grid-column: 1 / 3;
  @media (min-width: 1024px) {
    display: none;
  }
`
