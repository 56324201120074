import { ArtiffyGenerator } from 'pages'
import { MainContainer } from './styled-components'
import Content from './content'
import Footer from './footer/Footer'
import { useLocation } from 'react-router-dom'
import { useEffect } from 'react'

const BrandsLanding = () => {
  const location = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location])
  return (
    <>
      <MainContainer>
        <ArtiffyGenerator />
        <Content />
      </MainContainer>
      <Footer />
    </>
  )
}

export default BrandsLanding
