import styled from 'styled-components'

const UNDERCONSTRUCTION = () => {
  return <Wrapper> 🚧 Operating in Beta 🚧 </Wrapper>
}

export default UNDERCONSTRUCTION

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: radial-gradient(
    334.1% 100% at 50.22% 0%,
    rgba(64, 48, 102, 0.4) 0%,
    rgba(151, 100, 225, 0.4) 100%
  );
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 5px;
  font-family: 'Helvetica', sans-serif;
  display: grid;
  place-items: center;
`
