import Slider from 'react-slick'
import styled from 'styled-components'
import { ProgressStatusEnums } from './DummyData'

export const StyledSlider = styled(Slider)`
  width: 100%;
  position: relative;
  .carousel-item {
    text-align: center;
    transition: 1s;
    position: relative;
    display: flex !important;
    justify-content: center;
    align-items: center;

    img {
      width: 100%;
    }
    :active,
    :focus {
      outline: none;
    }
  }

  img {
    border-radius: 10px;
  }

  .slick-center .carousel-item {
    opacity: 1;

    .data-container {
      opacity: 1;
      transition: 1s all;
    }
  }

  .carousel-item:not(.slick-center .carousel-item) {
    opacity: 0.5;

    .data-container {
      opacity: 0;
    }
  }

  @media (min-width: 1024px) {
    .carousel-item.distance-one {
      transform: scale(0.8);
    }
    .carousel-item.distance-two {
      transform: scale(0.6);
      opacity: 0.4 !important;

      &.left {
        transform: translateX(80px) scale(0.6);
      }

      &.right {
        transform: translateX(-80px) scale(0.6);
      }
    }
    .carousel-item.distance-three {
      opacity: 0.3 !important;
      transform: scale(0.5);

      &.left {
        transform: translateX(180px) scale(0.5);
      }

      &.right {
        transform: translateX(-180px) scale(0.5);
      }
    }
    .carousel-item.distance-four {
      opacity: 0.2 !important;
      transform: scale(0.4);

      &.left {
        transform: translateX(320px) scale(0.5);
      }

      &.right {
        transform: translateX(-320px) scale(0.5);
      }
    }
    .carousel-item.distance-five {
      opacity: 0.1 !important;
      transform: scale(0.3);
    }
  }

  @media (max-width: 1024px) {
    padding: 0;
    margin-block: -120px;
    .carousel-item {
      scale: 0.5 !important;
    }
  }

  @media (max-width: 768px) {
    display: none !important;
  }
`

export const DataContainer = styled.div`
  position: absolute;
  width: 90%;
  left: 50%;
  transform: translate(-50%);
  background: linear-gradient(
    154.04deg,
    rgba(0, 0, 0, 0.9) -0.71%,
    rgba(19, 26, 27, 0.9) 95.15%
  );
  border-radius: 14px;
  display: flex !important;
  flex-direction: column;
  gap: 20px;
  bottom: 10px;
  font-size: 14px;
  font-family: 'Helvetica neue';
  color: white;
  padding: 20px 14px;
`

export const DataHeader = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  img {
    padding: 5px;
    width: 32px !important;
    aspect-ration: 1;
    background-color: white;
    border-radius: 50%;
  }
`

export const DataContent = styled.div`
  display: flex;
  gap: 10px;
`

export const Indicator = styled.p<{ status: ProgressStatusEnums }>`
  color: ${({ status }) =>
    status === ProgressStatusEnums.PROGRESS ? 'rgba(65, 235, 204, 1)' : 'red'};
`
