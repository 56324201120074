import {
  Typography,
  TypographyAlignEnums,
  TypographySizeEnums,
  TypographyVariantEnums,
} from 'components'
import {
  GetPrizesContainer,
  ScanQRContainer,
  ScanQRContent,
  Wrapper,
} from './styled-components'

const BannerSection = () => {
  return (
    <Wrapper>
      <GetPrizesContainer>
        <Typography
          variant={TypographyVariantEnums.TITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.CENTER}
        >
          Get Prizes
        </Typography>
        <Typography
          variant={TypographyVariantEnums.SUBTITLE}
          size={TypographySizeEnums.LARGE}
          align={TypographyAlignEnums.CENTER}
        >
          Participate in our augmented reality campaigns and stand a <br />
          chance to win exciting prizes!
        </Typography>
      </GetPrizesContainer>
      <ScanQRContainer>
        <img
          src={require('assets/images/campaigns/ambassador-qr.png')}
          alt="Campaign Qr Code"
          style={{ width: '100px' }}
        />
        <ScanQRContent>
          <Typography
            variant={TypographyVariantEnums.TITLE}
            size={TypographySizeEnums.MEDIUM}
            align={TypographyAlignEnums.LEFT}
          >
            Scan the QR code on your phone
          </Typography>
          <Typography
            variant={TypographyVariantEnums.TITLE}
            size={TypographySizeEnums.SMALL}
            align={TypographyAlignEnums.LEFT}
          >
            to unlock the full immersive experience
          </Typography>
        </ScanQRContent>
      </ScanQRContainer>
    </Wrapper>
  )
}

export default BannerSection
