import styled from 'styled-components'

export { default as Button } from './Button/Button'
export { ButtonVariantEnums } from './Button/button.interface'
export { default as Typography } from './Typography/Typography'
export {
  TypographySizeEnums,
  TypographyVariantEnums,
  TypographyAlignEnums,
} from './Typography/typography.interface'
export { default as Article } from './Article/Article'
export { default as WaitlistModal } from './WaitlistModal/WaitlistModal'
export { default as Campaign } from './Campaign/Campaign'
export { default as CustomVideoPlayer } from './CustomVideoPlayer/CustomVideoPlayer'
export { default as Select } from './Select/Select'
export { default as Reward } from './Reward'
export const ImagesContainer = styled.article`
  display: flex;
  gap: 30px;
  width: 40%;

  img {
    border-radius: 10px;
    width: 100%;
  }

  @media (max-width: 1024px) {
    width: 100%;
  }
`

export const ImageColumn = styled.div<{ transform?: number }>`
  display: flex;
  flex-direction: column;
  gap: 30px;
  ${({ transform }) => transform && `transform: translateY(${transform}px);`}
  width: 50%;
`

export const StyledBr = styled.br`
  @media (max-width: 768px) {
    display: none;
  }
`
