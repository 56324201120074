export interface CampaignProps {
  task: string
  reward: string
  location: string
  status: CampaignStatusEnums
  campaignLogoTitle: string
  campaignImage: string
  link?: string
  campaignLogo: string
  disabled?: boolean
}

export enum CampaignStatusEnums {
  ONGOING = 'Ongoing',
  FINISHED = 'Closed',
}
